@import "../../assets/variableStyles.scss";

#footer {
  .footer__links {
    padding-bottom: 30px;
    .antd-container {
      max-width: 1025px;
      margin: auto;
      @include mobile {
        padding: 0 15px;
      }
    }
    .footer-column {
      p {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 0;
        color: #1c2b39;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          margin-bottom: 2px;
          a {
            color: #0775e2;
            font-size: 15px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .footer_links_wrapper {
    background-color: #f6f7f8;
    min-height: 118px;
    padding: 5px 30px;
    .links_wrapper {
      max-width: 995px;
      margin: 0 auto;
      padding: 30px 40px;
      @include mobile {
        text-align: center;
        padding: 40px;
      }
      .social_links {
        align-items: center;
        text-align: center;
        display: flex;
        justify-content: center;
        a {
          background: #505c75;
          padding: 8px 12px;
          border-radius: 50%;
          margin: 0 7px;
          color: #fff;
          &:hover {
            background-color: #1c2b39;
          }
        }
      }
      .social_fb_text {
        @include mobile {
          display: none;
        }
        * {
          margin-right: 3px;
        }
        button.btn {
          padding: 0px 10px;
          font-size: 12px;
          font-weight: 700;
        }
        small a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .footer_bottom_section {
    background-color: #1c2b39;
    color: #969dac;
    .footer_social_info {
      list-style-type: none;
      background-color: #263545;
      padding: 10px 10px;
      @include mobile {
        display: none;
      }
      ul {
        display: flex;
        justify-content: center;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0;
          margin-right: 50px;
          i {
            font-size: 40px;
            margin-right: 10px;
          }
          .cake-icon {
            font-size: 25px;
            border: solid 2px #969dac;
            border-radius: 50%;
            padding: 8px;
          }
          .hotels-icon {
            font-size: 28px;
            font-weight: 800;
            border: solid 2px #969dac;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 11px;
            font-style: normal;
          }
          p {
            margin-bottom: 0px;
            color: #dcdee3;
            line-height: 0.8;
            // padding-left: 20px;
            strong {
              display: block;
              font-family: Ubuntu, sans-serif;
              font-size: 24px;
            }
          }
        }
      }
    }
    .bottom_countries {
      .countries-expand-mobile {
        display: none;
        @include mobile {
          display: block;
          font-size: 16px;
          line-height: 1.2;
          font-weight: 700;
          text-align: center;
          padding: 34px 16px 16px;
        }
      }
      .countries_links {
        max-width: 1100px;
        margin: 0 auto;
        padding: 0px;
        text-align: left;
        @include mobile {
          padding: 16px 40px;
        }
        .footer-box {
          position: relative;
          width: 100%;
          padding-bottom: 28px;
          @include mobile {
            padding-bottom: 12px;
          }
          .footer-bg-img {
            .background {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              margin: -25px;
              @include mobile {
                display: none;
              }
            }
          }
          .country-heading {
            font-weight: 700;
            color: #fff;
            line-height: 1.2;
            @include mobile {
              font-size: 16px;
              margin-bottom: 4px;
            }
          }
          .countries_list {
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            @include mobile {
              padding-top: 0px;
            }
            li {
              display: block;
              line-height: 1.6;
              padding: 4px 14px 4px 0;
              a {
                color: #969dac !important;
              }
            }
          }
        }
        .footr-inter-sites {
          width: 100%;
          text-align: left;
          padding-top: 25px;
          p {
            font-weight: 700;
            text-align: left;
            color: #fff;
            line-height: 1.2;
            margin-bottom: 0px;
            font-size: 13px;
            @include mobile {
              font-size: 16px;
            }
          }
          .footer-flex {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .cms-pages-links {
            display: block;
            flex-wrap: wrap;
            justify-content: start;
            @include mobile {
              justify-content: space-between;
            }
            li {
              display: block;
              line-height: 1;
              padding: 4px 18px 4px 0;
              a {
                color: #969dac;
                font-size: 14px;
                transition: all 0.8s ease 0s;
                line-height: 20px;
              }
            }
          }
        }
      }
      .footer-logos {
        text-align: center;
        padding: 10px;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        .logo1 {
          background: url("../../assets/images/footer/sprite_footer.png");
          background-position: -177px -95px;
          width: 78px;
          height: 30px;
        }
        .logo2 {
          background: url("../../assets/images/footer/sprite_footer.png");
          background-position: -51px -164px;
          width: 48px;
          height: 30px;
        }
        .logo3 {
          background: url("../../assets/images/footer/sprite_footer.png");
          background-position: -177px -65px;
          width: 87px;
          height: 30px;
        }
        .logo4 {
          background: url("../../assets/images/footer/sprite_footer.png");
          background-position: -255px -95px;
          width: 37px;
          height: 28px;
        }
        .logo5 {
          background: url("../../assets/images/footer/sprite_footer.png");
          background-position: -177px -35px;
          width: 103px;
          height: 30px;
        }
      }
      .footer-logos > div {
        margin-right: 30px;
        @include mobile {
          margin-right: 0px;
        }
      }
      .copyright {
        font-size: 12px;
        line-height: 1.2;
        text-align: left;
        padding: 10px 20px;
        border-top: 1px solid #ededed;
        text-align: center;
      }
      .social_wrapper {
        font-size: 20px;
        padding-bottom: 15px;
        text-align: left;
        a {
          color: white;
          .anticon {
            margin: 0 10px 10px 10px;
          }
        }
      }
    }
  }
}

@include mobile {
  .mobD-none {
    display: none;
  }
}

.footer-visa-images li {
  display: inline-block;
  padding: 0px 5px 5px 0px;
  margin-top: 10px;
}
.pay-ment-img {
  border-left: 1px solid #505050;
  border-right: 1px solid #505050;
  padding: 10px 10px;
}
.cms-pages-links li a:hover {
  text-decoration: underline !important;
  padding-left: 5px;
}
.last-sec-down {
  padding-left: 8px;
}
.your-trip-app {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.5px;
}
.g-store {
  margin: 15px 0;
  transform: perspective(1px) translateZ(0);
}
.g-store:hover {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
/*--address---*/
.footer-address-cc ul {
  list-style: none;
}
.footer-address-cc ul li {
  display: flex;
  margin-bottom: 14px;
}
.footer-address-cc ul i {
  margin-right: 7px;
  display: inline-block;
  padding-top: 0px;
  color: #ea1b37;
}
.footer-address-cc ul li p {
  font-weight: normal !important;
  font-size: 15px !important;
}
.footer-address-cc {
  margin-top: 10px;
}
.traveller-family-c2 h3 {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  transition: 0.3s;
  color: #ff6363;
}
.traveller-family-c2 p {
  margin-bottom: 0px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-align: center !important;
}
.add-logos-ft {
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 10px;
}
.ft-col-1 {
  padding-top: 8px;
}
.ft-col-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.bg-ft-col-22 {
  //background-image: linear-gradient(0deg,#15457b,#051423);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 94px;
  background: #fff;
  border-bottom: 1px solid;
  //border-top: 1px solid;
}
.bottom-postion-ft {
  position: relative;
}
.countries-top {
  padding-top: 130px;
}
.trv-img-cc-3 {
  height: 52px;
  width: 72px;
  display: block;
  margin: 0 auto;
  background-color: #fff;
  transition: all 0.5s ease;
  //box-shadow: 0 0px 1px #000;
}
.trv-img-cc-3:hover {
  transition: all 0.5s ease;
  transform: scale(1.1) perspective(1px);
  backface-visibility: hidden;
  cursor: pointer;
}
.our-affi-c1 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  //text-transform: uppercase;
  text-align: center;
}
.our-affi-c1::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 60%;
  margin: 0px auto;
  width: 10%;
  height: 3px;
  background: #505050;
}
.our-affi-c1 span {
  color: #ff6363;
}
.our-affi-c1-tr {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  //text-transform: uppercase;
  text-align: center;
}
.our-affi-c1-tr::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 60%;
  margin: 0px auto;
  width: 10%;
  height: 3px;
  background: #505050;
}
.our-affi-c1-tr span {
  color: #ff6363;
}
@media screen and (max-width: 768px) {
  .footer-address-cc {
    position: relative;
    top: 0px;
    right: 0px;
  }
  .bg-ft-col-22 {
    position: relative;
  }
  .countries-top {
    padding-top: 0px;
  }
  .trv-img-cc-3 {
    margin-bottom: 5px;
  }
}
.FTLocation{
  padding-Left:25px; 
  display:flex; 
  color:white;
  margin-Top:-25px;
}
