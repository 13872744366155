.hday-panel-21 {
  margin-top: 25px;
  margin-bottom: 65px;
}
.day-pk-img {
  border-radius: 6px;
}
.share-day-21 {
  margin-bottom: 0px;
  text-align: end;
}
.hd-row-1 {
  margin-bottom: 28px;
}
.h-21-bc p {
  margin-bottom: 0px;
}
.h-21-bc span {
  font-size: 13px;
}
.h-21-bc h3 {
  font-size: 20px;
}
.row-bc-222 {
  border-bottom: 1px solid #ddd;
  margin-bottom: 45px;
}
.inner-day-21 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: 0 0 0.25px currentColor;
  padding: 5px 15px !important;
  text-shadow: 0 0 0.25px currentColor;
  color: #008cff;
  font-weight: 700;
  box-shadow: 0 0 6px 0 rgb(0, 0, 0 / 20%);
  border-radius: 34px;
}
.inner-day-21 .ant-tabs-tab {
  padding: 5px 18px !important;
}

/*days--tabs--*/
.day-21-all {
  font-weight: 600;
}
.iti-day-11 {
  border: 1px solid #c8c8c8;
  box-shadow: 0 4px 8px 0 rgb(138, 107, 118 / 8%);
  padding: 10px 10px;
  border-radius: 4px;
  overflow: hidden;
}

.f1-day-flight {
  width: 40px;
}
.sg-day-1 {
  margin-bottom: 0px;
}
.time-day-11 {
  margin-bottom: 0px;
  flex-direction: column;
  display: flex;
  margin-right: 10px;
}
.derpart-day-10 {
  display: flex;
}
.span-21 {
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
}
.span-22 {
  font-size: 12px;
}
.line-bar-flight {
  position: relative;
  text-align: center;
  flex-direction: column;
  flex: 1;
}
.span-23 {
  font-size: 12px;
}
.fl-journey {
  left: 50%;
  margin-left: -12px;
  top: 15px;
  z-index: 1;
  position: absolute;
}
.round-icon-ff {
  width: 15px;
}
.flight-line-bar {
  margin-top: 5px;
  margin-bottom: 10px;
  height: 1px;
  background: #c3c3c3;
  display: block;
  position: relative;
}

.time-day-12 {
  margin-bottom: 0px;
  flex-direction: column;
  display: flex;
  margin-left: 10px;
}

.span-211 {
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
}
.span-222 {
  font-size: 12px;
}

/*flight-2--css*/
.iti-flight-w3 {
  display: flex;
}
.w3-flight-1 {
  width: 35%;
}
.flight-card-w3 {
  background: #fff;
  margin-bottom: 10px;
  height: 100%;
  background-image: linear-gradient(180deg, #fafcfd, #fff);
  border-radius: 8px;
  box-shadow: 0 2px -1px 0 rgb(19, 41, 104 / 20%);
  border-right: 1px solid #ccc;
}
.w3-flight-2 {
  width: 50%;
  display: flex;
}
.w3-city-1 {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.w3-loc-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
}
.w3-code {
  font-weight: 200;
  font-size: 18px;
  font-weight: 200;
  font-size: 18px;
}
.w3-break-line {
  border: 1px solid #0775e2;
  position: relative;
  margin: 0 5px;
  flex: 1 1;
}
.w3-inner-line {
  position: absolute;
  width: 100%;
  text-align: center;
}
.flight-card-w3 .ant-card-body {
  padding: 6px !important;
}
.w3-flight-3 {
  width: 35%;
  text-align: end;
  display: flex;
  flex-direction: column;
}
.stop-type-w3 {
  font-weight: 900;
  font-size: 11px;
  letter-spacing: 0.5px;
}
.stop-type-cls {
  font-size: 10px;
}
.w3-timings-21 {
  display: flex;
  align-items: center;
  margin-left: 0px;
  padding: 2px 0;
  justify-content: space-between;
}
.timing-wrap-w3 {
  position: absolute;
  top: 49px;
  left: 183px;
}
.plan-w3-21 {
  display: flex;
}
.plan-w3-21 ul {
  display: flex;
  align-items: center;
}
.plan-w3-21 ul li {
  margin-right: 5px;
}
.plan-w3-21 ul li p {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: bold;
}
.w3-time p {
  margin-left: 5px;
  display: flex;
  align-items: center;
  color: #ccc;
}
.w3-btn-left {
  text-align: center;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.w3-share-mail {
  display: flex;
}
.cl-type {
  color: #969dac;
  font-size: 15px;
  letter-spacing: 1px;
  padding-right: 4px;
}
.cl-currency {
  font-weight: 700;
  font-size: 25px;
}
.mail-share {
  font-size: 17px;
  color: #007baa;
  display: block;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-top: 3px;
}
.w3-sec-btn {
  background-color: #ea1b37;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 15px;
  font-weight: 700;
  padding: 6px 13px;
  display: none;
}

/*hotel---*/
.search-list-hotel-1 {
  margin-bottom: 20px;
}
.results-w3-1 {
  box-shadow: 0 2px 7px #dcdee3;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  background-color: #fff;
  cursor: pointer;
}
.w3-grid-1 {
  grid-column: 1;
  grid-row: 1 / span 3;
  width: 250px;
  position: relative;
}
.w3-img-11 {
  height: 230px;
}
.w3-grid-2 {
  grid-column: 2 / span 2;
  grid-row: 1;
  padding: 15px 15px 0;
}
.w3-img-11 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-icon-w3 {
  bottom: 21px;
  position: absolute;
  right: 21px;
  width: 46px;
  height: 46px;
  background: rgba(28, 43, 57, 0.6);
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
}
.gallery-icon-w3 .fa {
  font-size: 23px;
  line-height: 46px;
  color: #fff;
}
.tittle-w3-hotel {
  display: flex;
  align-items: baseline;
}
.hotel-start-info ul {
  font-size: 12px;
  color: #f6952d;
  display: block;
}
.hotel-start-info ul li {
  margin-right: 2px;
  display: inline-block;
}
.loc-w3-num {
  color: #333;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.loc-w3-num .anticon {
  color: #ea1b37;
  margin-right: 5px;
}
.add-w3-hotel {
  margin: 5px 0;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-w3-hotel p {
  margin-bottom: 0px;
}
.w3-grid-3 {
  grid-column: 4;
  grid-row: 1 / span 3;
  background-color: #f6f7f8;
  text-align: center;
  width: 210px;
  display: grid;
  align-items: center;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.w3-hr-info {
  color: #969dac;
  font-size: 18px;
  margin-bottom: 15px;
}
.w3-hr-info span {
  color: #1c2b39;
  line-height: 27px;
  font-size: 24px;
  font-weight: 700;
}
.hr-btn-one {
  padding: 0 30px;
}
.choose-hr-w3 {
  background-color: #ea1b37;
  border-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 3px;
  height: auto;
  display: none;
}
.joining-line {
  border-left: 1px solid #cecece;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  padding-left: 12px;
  font-size: 12px;
  font-weight: 700;
  color: #9b9b9b;
}
.hotel-md-w3 {
  margin-top: 0px;
}
.day-flex-times {
  display: flex;
}
.month-name-date {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 700;
  margin-right: 5px;
}
.cars-ratings-w3 {
  padding: 5px;
  margin-top: 0px;
  border: 1px solid #c8c8c8;
}

/*last-tab--*/
.summary-day {
  color: #9b9b9b;

  margin-bottom: 0px;
  font-weight: 900;
}
.summary-plan-v3 {
  flex-wrap: wrap;
  border-bottom: 1px solid #d9d7d7;
  flex: 1;
  list-style: square;
}
.summary-plan-v3 li {
  display: inline-flex;
  padding: 0px 51px 0px 0px;
  list-style: square;
  color: #000;
}
.tt-row-v3 {
  margin-top: 30px;
}
.inner-line-v3 .ant-tabs-tab {
  font-size: 15px !important;
  font-weight: bold;
  padding: 6px 20px;
}
.inner-day-21 .ant-tabs-tab {
  font-size: 13px !important;
  font-weight: bold;
}
.day-tt-v3 {
  width: 100%;
  height: 194px;
  border-radius: 6px;
  padding-left: 15px;
}
.img-funtion-visa {
  display: flex;
}
.visa-vv {
  width: 36px;
  height: 36px;
}
.read-faqs-v3 {
  font-size: 15px;
  font-weight: bold;
}
.policies-v3 {
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  flex-direction: column;
  background: #f1f1f1;
  text-align: center;
  margin: 0px 10px;
  border-radius: 6px;
}
.v3-rating-policy {
  padding-top: 20px;
}
.v3-p1 {
  color: #1a7971;
  margin-bottom: 0px;
}
.v3-p2 {
  color: #1a7971;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: bold;
}
.v3-p3 {
  color: #000;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: bold;
}
.v3-p4 {
  margin-bottom: 0px;
  font-size: 10px;
}
.v3-p5 {
  margin-bottom: 0px;
  font-size: 12px;
}
.pkg-v3-bg {
  height: 100%;
  width: 100%;
  background-color: #2f81ed;
  background-image: linear-gradient(to right, #2f80ed, #56ccf2);
  padding-top: 7px;
  padding-bottom: 7px;
}
.pkg-v3-search {
  background: #ea1b37 !important;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 20px;
  outline: 0;
  border: 0;
  font-weight: 600;
  width: 100%;
  height: 45px;
}
.v3-top-pkg .ant-form-item {
  margin-bottom: 0px;
}
.cance-pkg-modi {
  margin-top: 20px;
}
.visa-disc-v3 {
  margin-bottom: 4px;
}
/*left--price-line--*/
.left-price-flex {
  border: 1px solid #c8c8c8;
  box-shadow: 0 0px 1px 0 rgb(138, 107, 118 / 8%);
}
.price-all-v3-from {
  background: #e2f2ff;
  padding: 8px 16px;
  margin: 10px;
  display: flex;
}
.right-left-ve {
  padding-left: 10px;
}
.price-all-v3-from p {
  margin-bottom: 0px;
}
.price-v3-1 {
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
}
.price-v3-2 {
  font-size: 12px;
  padding-left: 6px;
}
.left-price-flex .ant-collapse-item {
  margin-top: 0px !important;
}
.left-price-flex .ant-collapse-content-box {
  padding: 0px !important;
}
.book-v3-price22 {
  background: #ea1b37 !important;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 14px;
  outline: 0;
  border: 0;
  font-weight: 600;
  margin: 0 auto;
  border-radius: 20px;
  width: 93%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.offers-app-v3 li {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 6px;
}
.brdrTop1 {
  border-top: 1px solid #c8c8c8;
}
.append-v3 {
  margin-bottom: 2px;
  font-weight: 700;
}
.applied-v3 {
  font-size: 12px;
  margin-bottom: 0px;
}
.amount-app-v3 {
  text-align: end;
  font-weight: 700;
  display: block;
  margin-left: auto;
}
.top-line-bb1 {
  border-top: 1px solid #c8c8c8;
}
.v3-div-2 {
  display: flex;
}
.cms-pages-width-v3 {
  max-width: 1140px !important;
  margin: auto;
  padding: 0 15px;
}
.charges-v3-add {
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
  padding: 6px 6px;
  border-bottom: 1px solid #c8c8c8;
}
.v3-additions-1 {
  margin-top: 25px;
}
.v3-padding-20 {
  margin-bottom: 0px;
  padding: 6px 6px;
  font-size: 13px;
}
.inner-day-21 .ant-tabs-nav {
  padding: 17px 0px 17px 3px;
  background: #fafdff;
}
.photo-gallery-v3 {
  position: absolute;
  top: 170px;
  left: 10px;
}
.photo-gallery-v3 p {
  color: #fff;
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 14px;
  cursor: pointer;
}
.width-padding-v3 {
  max-width: 8.5% !important;
}
.left-change-date {
  border: solid 0.7px #e7e7e7;
  background-color: #d7fae2;

  align-items: center;
  padding: 10px;
}
.v3-date-pic1 {
  font-size: 12px;
  margin-bottom: 0px;
  padding-left: 6px;
}
.v3-date-pic2 {
  font-size: 12px;
  color: #008cff;
  margin-bottom: 0px;
  text-align: end;
}
.change-img-vv {
  height: 25px;
  width: 25px;
}
.v3-input {
  height: 45px;
  font-size: 14px;
  border-radius: 4px;
  background-color: #f5f5f5;
}
.v3-input-1 {
  border-radius: 4px;
  background-color: #f5f5f5;
}
.v3-panel-date {
  display: flex;
}

@media screen and (max-width: 768px) {
  .day-tt-v3 {
    padding-left: 0px !important;
    margin-top: 15px;
  }
  .results-w3-1 {
    display: block;
  }
  .w3-grid-3 {
    width: 100%;
  }
  ul.date-month-dist li {
    display: inline;
    padding: 0px 5px 0px 9px;
  }
  ul.date-month-dist li a {
    cursor: pointer;
  }
  .pkg-v3-bg .ant-collapse .ant-collapse-header {
    padding: 0;
    display: inline-block !important;
  }
  .include-v3-btn {
    margin-top: 0px !important;
  }
  .rating-v3-in p {
    margin-top: 0px !important;
  }
}
.pkg-v3-bg .ant-collapse {
  background: transparent;
  border: none;
}
.pkg-v3-bg .ant-collapse .ant-collapse-item {
  border: none;
  text-align: right;
  background: transparent !important;
  box-shadow: none;
}
.pkg-v3-bg .ant-collapse .ant-collapse-header {
  padding: 0;
  display: none;
}
.pkg-v3-bg .ant-collapse .ant-collapse-content {
  text-align: left;
  border: none;
  background: transparent;
}
.pkg-v3-bg .ant-collapse .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
}
.pkg-v3-bg .v3-hide-pkgv3 {
  padding: 0;
  display: none;
}
.v3-hide-pkg {
  margin: 10px 0;
  float: right;
  cursor: pointer;
  color: #000000 !important;
  background: #ffffff !important;
  padding: 5px 5px;
  border-radius: 5px;
  font-size: 16px !important;
}

/*popup--css*/
.v3-popup-heading {
  font-size: 20px;
}
.pop-up-img1 {
  border-radius: 6px;
  width: 100%;
}
.carousel-v3-modal {
  padding-top: 20px;
}
.hotel-popup-set .ant-modal-footer {
  display: none;
}
.date-popup-check {
  display: flex;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  margin-top: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.inner-room-modal p {
  margin-bottom: 0px;
}
.inner-room-modal-1 p {
  margin-bottom: 0px;
}
.inner-room-modal-1 {
  padding-left: 15px;
}
.v3-graph-hotel {
  margin-top: 15px;
}
.about-v3 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}
.hotel-v3-plan-w {
  margin-bottom: 4px;
}
.amenities-v3 {
  display: flex;
  flex-wrap: wrap;
}
.amenities-v3 li {
  padding: 5px 35px 0px 0px;
}
.about-v4 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 10px;
}
.roomimg1-v3 {
  border-radius: 6px;
  width: 150px;
  height: 100px;
}
.available-v3-room li {
  font-size: 12px;
  line-height: 18px;
}
.available-v3-room {
  padding-bottom: 10px;
}
.include-v3-btn {
  background: #ea1b37 !important;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 13px;
  outline: 0;
  border: 0;
  font-weight: 600;
  margin-left: auto;
  border-radius: 20px;
  margin-top: 65px;
}
.rating-v3-in {
  display: flex;
}
.rating-v3-in p {
  margin-top: 71px;
  font-weight: bold;
}
.line-graph-hotel-v3 {
  border-bottom: 1px solid #c8c8c8;
  padding-top: 10px;
  padding-bottom: 10px;
}
.review-name-v3 {
  margin-bottom: 0px;
  font-size: 13px;
}
