.holidays-font{
    font-size: 30px !important;
}
.holidays_search_container{
    margin-top: -36px;
}
.holidays_search_container .holidays_banner {
    position: relative;
    z-index: 1;
}

.holidays_search_container .holidays_banner .heading_text {
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, 50%);
    text-align: center;
    width: 100%;
    max-width: 1050px;
    top: 34%;
}

.holidays_search_container .holidays_banner .hotel_search {
    position: absolute;
    bottom: 49px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1040px;
}
.holidays_search_container .holidays_banner .heading_text h2 {
    color: #fff;
    font-weight: 700;
    font-size: 34px;
    font-family: ubuntu;
    margin-bottom: 35px;
}
.holidays_search_container .holidays_advantages {
    max-width: 1140px;
    margin: 70px auto;
}
.holidays_search_container .holidays_advantages .holidays-advantages-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box {
    display: flex;
    align-items: center;
    flex-basis: 30%;
    flex-shrink: 0;
    flex-grow: 0;
}


.holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box .holidays-adv-box-wrapper {
    padding-left: 15px;
}

.holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box .holidays-adv-box-wrapper p {
    font-weight: 700;
    margin-bottom: 3px;
}

.holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box .holidays-adv-box-wrapper small {
    font-size: 90%;
    font-family: Ubuntu;
}
.holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box img{
    width: 64px;
    height: 64px;
    background-size: cover;
    background-position: 50%;
}
.holidays-search{
    background: #f5f5f5 !important;
    padding-top: 50px;
}
.holidays-search-apps{
    background: transparent !important;
}
.top-cities a{
    display: inline-block;
    margin-bottom: 12px;
    color: #666;
    line-height: 16px;
    margin-right: 10px;
}
.top-cities a::after{
    content: "";
    margin-left: 10px;
    border-left: 1px solid #b2b2b2;
    height: 12px;
    display: inline-block;
    vertical-align: middle;
}
.apps-holidays{
    justify-content: space-between;
    padding: 40px 28px 32px 40px;
    background-color: #ffdac4;
    width: 390px;
    height: 100%;
    
}
.apps-holidays h4{
    text-align: center;
}
.ios-andriod-app img{
    display: flex;
    margin: 0 auto;
}
.img-travels-1{
    height: 228px;
    width: 100%;
}



@media screen and (max-width: 768px){

.holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box {
    flex-wrap: wrap;
    justify-content: center;
}

.holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box .holidays-adv-box-wrapper {
    text-align: center;
    width: 90%;
    line-height: 1.3;
    margin-top: 20px;
    margin-bottom: 20px;
}

}
/*---carosuell--slider--*/
.home_carousel {
    margin-bottom: 30px;
    h3 {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 40px;
        font-family: Ubuntu,sans-serif;
        font-weight: 700;
        font-size: 34px;
        line-height: 40px;
    }
    .carousel-section {
        // max-height: 500px;
        .right-layer {
            position: absolute;
            height: 100%;
            width: 17%;
            z-index: 1;
            background-color: white;
            opacity: 0.5;
        }
        .left-layer {
            position: absolute;
            height: 100%;
            width: 17%;
            z-index: 1;
            background-color: white;
            opacity: 0.5;
            right: 0;
        }
        .slides-section {
            // padding: 0px 50px;
        }
    }
}

.carousel-img {
    width: 99%;
    height: 290px;
}
.hotel-slide {
    background-color: #ea1b37;
}
.bulb-slide {
    background-color: #00d2e8;
    
}
.flight-bulb {
    &:hover {
        .hotel-letter {
            display: none;
        }
        .hovered-travel-content {
            display: block;
            padding: 30px 0;
            ul {
                li {
                    margin-bottom: 15px;
                    a {
                        color: #fff;
                        position: relative;
                        &::before {
                            height: 0;
                            width: 0;
                            border-top: 4px solid transparent;
                            border-bottom: 4px solid transparent;
                            border-left: solid transparent;
                            border-left-color: white;
                            content: "";
                            position: absolute;
                            left: -6px;
                            top: 6px;

                        }
                    }
                }
            }
        }
    }
}
.custom-slide {
    color: #ffffff;
    padding: 40px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        line-height: 120%;
        font-size: 24px;
        text-align: center;
    }
    .hotel-letter {
        font-size: 70px;
        border: solid 7px #fff;
        border-radius: 50%;
        padding: 0px 30px;
    }
    .hotel-letter-icon {
        padding: 0 15px;
    }
    .cash-icon-carousel {
        padding: 0 20px;
    }
}
.slide-wrapper {
    position: relative;
    .slide-over-layer {
        background: linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.5));
        position: absolute;
        height: 100%;
        width: 99%;
    }
    .check-details-btn {
        display: none;
    }
    &:hover {
        .slide-hoverlayer {
            background: linear-gradient(to right,rgba(0,0,0,0.6),rgba(0,0,0,0.6)) !important;
            // z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 99%;
        }
        .check-details-btn {
            display: block;
            .btn-styles {
                background-color: #0775e2;
                font-weight: 900;
                letter-spacing: 1px;
                margin-top: 18px;
                border-radius: 3px;
                text-align: center;
                border: none;
                color: #fff;
                font-size: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 23px 30px;
            }
        }
    }
    
    img {
        transition: opacity .3s;
        // opacity: 1;
        z-index: 1;
    }
    .content-on-slide {
        font-family: Ubuntu,sans-serif;
        .top-text {
            position: absolute;
            top: 10px;
            left: 20px;
            color: #fff;
            text-transform: uppercase;
        }
        .bottom-text {
            position: absolute;
            bottom: 10px;
            left: 20px;
            color: #fff;
            hr {
                color: #fff;
            }
            .hr-top {
                p {
                    font-size: 13px;
                }
                .text-flight {
                    display: flex;
                    align-items: center;
                    // justify-content: space-between;
                    i {
                        font-size: 25px;
                    }
                    h5 {
                        color: #fff;
                        font-weight: 800;
                        letter-spacing: .5px;
                        padding-left: 10px;
                        font-size: 25px;
                    }
                }
            }
            .price-bottom-hr {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-top: solid 1px #fff;
                padding-top: 10px;
                h6 {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 700;
                }
                span {
                    padding: 0px 6px 3px 10px;
                    font-size: 28px;
                    font-weight: 800;
                }
                p {
                    font-size: 13px;
                    font-weight: 800;
                }
            }
            
        }
    }
}

/* ============  customize the react slick styles ================= */

.slick-next::before {
    content: "\f105";
    cursor: pointer !important;
    font-size: 30px;
    color: #fff;
    font: normal normal normal 40px/1 FontAwesome;
}
.slick-next {
    cursor: pointer !important;
    right: 30px;
    border: solid 4px #fff;
    border-radius: 50%;
    line-height: 0 !important;
    height: 60px !important;
    width: 60px !important;
    opacity: 1 !important;
    z-index: 1;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.slick-prev::before {
    content: "\f104";
    cursor: pointer !important;
    font-size: 30px;
    font: normal normal normal 40px/1 FontAwesome;
    color: #fff;
}

.slick-prev {
    cursor: pointer !important;
    left: 23px;
    border: solid 4px #fff;
    border-radius: 50%;
    line-height: 0 !important;
    height: 60px !important;
    width: 60px !important;
    opacity: 1 !important;
    z-index: 1;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.hovered-travel-content {
    display: none;
}

