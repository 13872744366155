/* Trip section container */
.trip-help-row {
    margin-top: 20px;
  }
  
  .trip-help-cc {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 15px;
  }
  
  /* Make the images responsive */
  .activity-panel-img-cc img {
    width: 70%; /* Allow the images to scale with the container */
    max-width: 100%; /* Ensure images do not overflow */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional: Image shadow */
    transition: transform 0.3s ease-in-out;
  }
  
  .activity-panel-img-cc img:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  /* Link Styles */
  .title-link a {
    font-size: 20px;
    font-weight: bold;
    color: #FF5733;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 30px;
    background-color: #fff;
    border: 2px solid #FF5733;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .title-link a:hover {
    background-color: #808080;
    color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-10px); /* Slight zoom effect on hover */
  }
  
  /* PDF Link Styles */
  .pdf-link a {
    font-size: 16px;
    color: #000000;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .pdf-link a:hover {
    color: #1565C0;
  }
  
  /* Responsive Design for Smaller Screens */
  @media (max-width: 768px) {
    .activity-panel-img-cc {
      text-align: center;
      padding: 10px;
    }
  
    .title-link a {
      font-size: 16px;
      padding: 8px 15px;
    }
  
    // .pdf-link a {
    //   font-size: 14px;
    //   padding: 5px 10px;
    // }
  
    .activity-panel-img-cc img {
      height: auto;
      max-width: 100%;
    }
  }
  
  /* Additional small screen optimization */
  @media (max-width: 480px) {
    .trip-help-cc {
      font-size: 20px; /* Smaller font for smaller devices */
    }
  
    .activity-panel-img-cc {
      margin-bottom: 20px; /* Add some space between panels */
    }
  
    .activity-panel-img-cc img {
      width: 90%; /* Adjust image size for small screens */
    }
  }
  