.buildquo-wrapper {
  .saved-btn {
    background-color: #ea1b37;
    border-color: transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border-radius: 3px;
    padding: 2px 10px;
    &:focus,
    &:active,
    &:hover {
      background-color: #ea1b37;
      color: #fff;
    }
  }

  .dark-btn {
    background-color: #505c75;
    border-color: transparent;
    padding: 2px 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border-radius: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus,
    &:active,
    &:hover {
      background-color: #505c75 !important;
      color: #fff;
    }
  }
}

.total-quotationdata {
  text-align: right;
}
.total-quotationdata p {
  margin-bottom: 0px;
}
.hotel-tour-quotation {
  margin-top: 25px;
}
.hotel-tour-quotation h6 {
  font-weight: bold;
}
.hotel-tour-quotation p {
  margin-bottom: 0px;
}
.drop-btns-value {
  padding-top: 20px;
  margin-top: 0px;
}
.top-block-quiet {
  .menu-wrapper {
    cursor: pointer;
    width: 100%;
    background-color: #ea1b37;
    color: #fff;
    padding: 0 8px;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    font-weight: 800;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ea1b37;
    transition: 0.3s linear;
    &:hover {
      color: #096dd9 !important;
      background-color: transparent !important;
      border: 1px solid #096dd9;
    }
  }
}

.markuprowvalue {
  padding-left: 4px;
  padding-right: 4px;
}

.prewbtn {
  width: 100%;
  background: #ea1b37;
  padding: 0 8px;
  height: 34px;
  line-height: 34px;
  font-size: 1em;
  border: 0px;
}
.terms-reviewfroms {
  padding-top: 25px;
}
.listofterms li {
  line-height: 24px;
  font-size: 14px;
}
.optiondatainfo {
  border-right: 1px solid #ddd;
}
.tourdata-card {
  padding-left: 10px;
}
.data-icons-info-bal {
  margin-top: 25px;
}
.data-icons-info-bal .ant-card-body {
  padding: 10px;
}
.viewallquo {
  text-align: right;
}

.markuprowvalue-hhh {
  background-color: #f8f8f8 !important;
  border: 1px solid #ddd !important;
  padding: 10px 10px;
}
.markuprowvalue-hhh h3 {
  font-size: 14px;
  font-weight: 600;
}
.fn-hotel-bar {
  padding: 10px 10px;
}
.fn-hotel-bar p {
  margin-bottom: 0px;
  font-size: 14px;
  color: #666;
  font-weight: 600;
}
.picpara {
  padding-left: 20px;
}
.fn-hotel-bar .fa {
  color: #ea1b37;
}
.tuneoptions {
  border-bottom: 1px solid #ddd;
}
.img-cotation-total {
  width: 100%;
  // height: 100%;
}
.bty-info-hote {
  padding-left: 8px;
}
.info-hotel-main {
  height: 100%;
}
.start-main-rating li {
  display: inline-flex;
  padding: 0px 4px;
}
.start-main-rating li .user-r {
  color: #f2d329;
}
.time-and-date-main li {
  display: block;
  padding: 0px 4px;
  line-height: 28px;
  font-weight: 600;
}
.num-total {
  text-align: right;
  padding-right: 10px;
}
.num-total p {
  font-size: 14px !important;
  color: #000;
  font-weight: 600;
}
.last-date-maion li {
  display: inline-flex;
  padding: 0px 4px;
  line-height: 28px;
  font-weight: 600;
}
.fn-bold {
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .bar-admin-width {
    width: 100%;
    padding: 0px 0px;
  }
  .total-quotationdata {
    text-align: left;
  }

  .mobidata-hotel {
    display: block !important;
  }
  .menu-tour-option {
    display: none;
  }
  .saved-btn {
    width: 100%;
    font-size: 12px !important;
    padding: 2px 0px !important;
  }
  .buildquo-wrapper {
    padding: 0px !important;
  }
  .dark-btn {
    font-size: 12px !important;
  }
}
.one-quo {
  font-size: 16px;
}
.one-quo {
  font-size: 17px;
}
.mobidata-hotel {
  display: none;
}

.prewbtn:hover {
  color: #096dd9 !important;
  background: transparent !important;
  border: #096dd9 1px solid;
}
.flex-end-markup {
  justify-content: flex-end;
  margin-bottom: 20px;
}
.data-icons-info-bal h6 {
  font-size: 14px;
}
.data-icons-info-bal h5 {
  font-size: 14px;
}
.data-icons-info-bal h3 {
  font-size: 17px;
  font-weight: 700;
}
.quo-border-sec {
  border-radius: 5px;
}
.top-block-quiet {
  padding-bottom: 10px;
}
.tic-number-from {
  font-weight: bold;
}
.one-quo-acti {
  font-size: 17px !important;
  font-weight: 700;
}
.one-two-acti {
  font-size: 17px !important;
  font-weight: 700;
}
.hotel-room-info {
  background-color: #1e4167;
  width: 60px;
  padding: 4px 5px;
  font-size: 18px;
  text-align: left;
  border-radius: 4px;
  color: #fff;
}
.num-of-room-book {
  font-size: 15px;
  font-weight: bold;
}
.tours-book-method {
  margin-top: 0px;
}

/*build--total*/

.hotel-list-prime-1 li {
  display: inline-block;
}
.wt-hotel-1,
.wt-hotel4 {
  line-height: 18px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
}
.wt-hotel-1 {
  width: 140px;
  font-size: 13px;
}

.wt-hotel4 {
  width: 370px;
  font-size: 14px;
  text-align: right;
}
.hotel-list-prime-1 {
  padding-top: 25px;
}
.review-name-hotel {
  font-weight: bold;
}
.hotel-tour-quotation .ant-card-body {
  padding: 10px !important;
}

.rate-block-info {
  color: #000;
}
