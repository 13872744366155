@import "../../assets/variableStyles.scss";

.social-login {
  text-align: center;
}

.social-registration {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  Button {
    background-color: #fff;
    color: #000;
    border: none;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 2em !important;
    margin-left: 10px !important;
    &:hover {
      background-color: #fff;
    }
    .fbImg {
      max-width: 20px;
      margin-right: 5px;
    }
  }
  .facebook-btn {
    margin: 0px !important;
    // margin-right: 45px;
  }
}

.register-form {
  .input-size {
    // padding: 5px 35px;
    max-width: 425px;
    margin: auto;
    .password-input {
      border-right: solid 1px #fff;
    }
    .pwd-append {
      background: #fff;
      border-left: solid 1px #fff;
      height: 52px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      // i {
      //     @include mobile {
      //         color: #000;
      //     }
      // }
    }
    .error-msg {
      background: #da4c4b;
      color: #fff;
      padding: 3px 0px;
      margin-top: -11px;
      font-size: 14px;
      text-align: left;
      padding-left: 5px;
    }
    input {
      margin-bottom: 10px;
      font-size: 17px;
      padding: 25px 15px;
    }
    .agree-terms {
      display: flex;
    }
    .terms-privacy {
      color: #007bff;
      padding: 0px 4px;
    }
  }
  .check-terms-privacy {
    line-height: 1;
    .check-agree {
      display: flex;
      .agree-text {
        text-align: left;
        line-height: 1.5;
      }
    }
  }
  .unlock-offers {
    text-align: left;
    .check-offer {
      display: flex;
      p {
        margin-bottom: 0px;
      }
    }
  }
  .register-btn {
    background-color: #ea1b37;
    border: none;
    font-size: 22px;
    font-weight: 600;
    // max-width: 313px;
    padding: 8px 1px;
    margin: auto;
    margin-top: 18px;
    margin-bottom: 26px;
    max-width: 425px;
    &:hover {
      background-color: #ea1b37;
    }
    @include mobile {
      margin-bottom: 20px;
    }
  }
  .forgot-text {
    text-align: center;
    color: #0775e2;
    &:hover {
      text-decoration: underline;
    }
  }
  .forgot-link {
    text-align: center;
    color: #0775e2;
    &:hover {
      text-decoration: underline;
    }
  }
}
.register-acc {
  display: flex;
  justify-content: space-evenly;
  a {
    color: #000;
  }
}
hr {
  max-width: 425px;
}
.forgot-password {
  text-align: center;
  @include mobile {
    padding: 0;
  }
  .dont-remember-text {
    font-weight: 600;
    font-size: 18px;
  }
  .label-text {
    p {
      font-size: 16px;
    }
  }
  .back-to-login {
    margin-top: 10px;
  }
}
#hiddenPassword {
  display: none;
}
#loginForm {
  .ant-col {
    margin-left: 0 !important;
  }
}

.login-btn {
  background-color: #ea1b37 !important;
  border-color: #ea1b37 !important;
}
.pop-mobi-img {
  width: 100%;
  border-radius: 20px;
  padding-top: 30px;
}
.login-modal .ant-modal-body {
  padding: 0px !important;
}
.login-modal .ant-modal-content {
  border-radius: 18px !important;
}

@media screen and (max-width: 768px) {
  .img-pop-login {
    flex-direction: column-reverse !important;
  }
}
.login-text h3 {
  font-size: 18px;
}
.remember-modal .ant-modal-content {
  border-radius: 18px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent;
}
.ant-form-item-explain {
  text-align: left;
}
