.booking-option-cart {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  color: #38548b;
  padding-left: 0px;

  margin-top: 0px;
}

.contact-info {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  color: #424242;
  padding-left: 0px;
  padding-top: 10px;
  margin-top: 20px;
}

.total-booking {
  padding: 12px 24px;
  line-height: 26px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  word-wrap: break-word;
  background-color: #f5f5f5;
}
.doument-name {
  height: 33px;
}
.form-rules {
  margin-top: 25px;
}
.now-total-btn {
  text-align: right;
}
.payment-complete {
  margin-top: 25px;
}

.info-item li {
  display: inline-block;
}
.date-credits {
  width: 215px;
  line-height: 18px;
  font-size: 14px;
  color: #999;
}
.date-item-icon {
  width: 30px;
  line-height: 18px;
  font-size: 14px;
  color: #999;
}
.date-item {
  width: 140px;
  line-height: 18px;
  font-size: 13px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 0px;
}

.date-item-1 {
  width: 200px;
  line-height: 18px;
  font-size: 14px;
  color: #000000;
  text-align: right;
  font-weight: 600;
  margin-bottom: 0px;
}
.item-ctrd-two {
  margin-top: 25px;
}
.total-pay-cost-v {
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  color: #ff5722;
}
.rupee-cost {
  font-size: 20px !important;
  line-height: 20px;
  font-weight: bold;
  color: #ff5722 !important;
}
@media only screen and (max-width: 767px) {
  .mobile-offset {
    margin-left: 0% !important;
  }
  .mobile-row-res {
    margin-top: 0% !important;
  }
  .nextactivity {
    padding-left: 0px !important;
  }
}
.details-offer-card {
  margin-top: 25px;
}
.payment-bottom {
  margin-bottom: 0px !important;
}
.total-card-bar .ant-collapse-borderless {
  background-color: transparent !important;
}
.card-total-option .ant-card-body {
  padding: 0px !important;
}
.total-cost-user {
}
.offdata {
  color: red;
}
.offdata-1 {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}
.dataicon {
  font-size: 700;
  font-size: 18px;
}
.barselecticon {
  width: 97% !important;
}
.checkout-activity-card {
  border-radius: 8px;
  margin-top: 15px;
  box-shadow: 0 2px 5px 0 #13296833;
  .ant-card-body {
    .ant-collapse {
      .ant-collapse-item {
        border-bottom: none !important;
        .ant-collapse-header {
          font-size: 16px;
          font-weight: 600;
          color: #38548b;
        }
      }
    }
  }
}
.activites-cheout-nav {
  background: #002172;
  padding: 15px 0;
}
.nextactivity {
  padding-left: 8px;

  margin-bottom: 0px;
}
.nextactivity a {
  color: #ffffff;
}

.fromsbills {
  padding-left: 6px;
  padding-right: 6px;
}
.corner-left {
  padding-left: 8px;
  padding-right: 8px;
}
.roomone-infi {
  background-color: #ea1b37;
  border-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 3px;
}
.info-note {
  margin-bottom: 0px;
}
.bar-admin-width-quo {
  width: 1170px;
  padding: 0px;
}
@media only screen and (max-width: 768px) {
  .bar-admin-width-quo {
    width: 100%;
  }
  .date-item-1 {
    text-align: left;
    line-height: 24px;
  }
}
