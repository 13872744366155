@import "../../assets/variableStyles.scss";

.deals-section {
  .deals-wrapper {
    background-image: url("../../assets/images/deals_hero_img.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    margin-top: -67px;
    padding-top: 387px;
    position: relative;
    .bread-crumb-block {
      position: absolute;
      top: 70px;
      ul.breadcrumb {
        padding: 10px 16px;
        list-style: none;
        background-color: transparent;
      }
      ul.breadcrumb li {
        display: inline;
        font-size: 12px;
      }
      ul.breadcrumb li + li:before {
        padding: 8px;
        color: #dcdee3;
        content: "/\00a0";
      }
      ul.breadcrumb li a {
        color: #dcdee3;
        text-decoration: none;
      }
      ul.breadcrumb li a:hover {
        color: #dcdee3;
        text-decoration: underline;
      }
      ul.breadcrumb li span {
        color: #fff;
        font-weight: 700;
      }
    }
    .form-section-on-img {
      margin-top: -245px;
      .imp-text {
        h1 {
          color: #fff;
          text-align: center;
          font-size: 34px;
          line-height: 40px;
          font-family: "Ubuntu", sans-serif;
        }
      }
      .form-wrapper {
        .form-inside-card {
          padding: 10px;
        }
        .form-src-dest {
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            margin-bottom: 0px !important;
            font-size: 15px;
            font-weight: 600;
          }
          .search-form-item {
            width: 100%;
            margin: 0px 10px;
          }
        }
        .search-deals {
          text-align: center;
          button {
            background-color: #ea1b37;
            color: #fff;
            height: 40px;
            border: none;
            border-radius: 3px;
            font-size: 20px;
            text-align: center;
          }
        }
      }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px;
      padding: 4px 12px;
      color: #0775e2;
      font-size: 16px;
      border: solid 1px #bcd5f5;
    }
    .ant-select-single:not(.ant-select-customize-input)::placeholder {
      color: #0775e2;
    }
  }
  .view-deals-wrapper {
    padding: 30px 0px;
    .our-inspirations {
      text-align: center;
      h2 {
        font-weight: 700;
        font-family: "Ubuntu", sans-serif;
      }
    }
    .sorting-wrapper {
      display: flex;
      justify-content: end;
      align-items: center;
      border: solid 0px #ccc;
      padding: 10px;
      margin-top: 0px;
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border: none !important;
      }
    }
    .delas-view-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 10px;
      .view-icon {
        font-size: 23px;
        padding-right: 13px;
      }
      .list-icon {
        color: #0775e2;
      }
    }
    .deals {
      padding: 0px 20px;
      @include mobile {
        padding: 20px 0;
      }
    }
  }
}
