
.booking-form .ant-form-item-label > label {
    color: white;
    font-weight: bold;
}
.section-title h2 {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
	color: #fff;
}

.section-title p {
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
	color: #fff;
}
.card-body ul li {
    display: flex;
    align-items: baseline;
    margin-bottom: 8px;
}

.card-body ul li svg {
    margin-right: 8px;
}

.testimonial {
    padding: 50px 0;
    background-color: #f9f9f9;
}
.testimonial .section-title h2 {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}
.testimonial .card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    background-color: #fff;
}
.testimonial iframe {
    width: 100%;
    height: 200px;
    border-radius: 10px;
}

.testimonial img {
    border-radius: 10px;
    width: 100%;
    height: auto;
}

.list-unstyled li h5 {
    margin: 0 0 5px 10px;
    font-size: 1.25rem;
    font-weight: 600;
    color: #fff;
}
.container .d-flex div {
    text-align: center;
    flex: 1 1 30%; /* Adjust the flex-basis for responsive stats */
    margin-bottom: 20px; /* Add spacing on small screens */
}
.container .d-flex {
    flex-wrap: wrap; /* Allow wrapping for small screens */
    margin-top: 20px;
}
.container .d-flex h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
}
.container .d-flex p {
    font-size: 0.9rem;
}
// Future Museum Heading
.container h2 {
    font-size: 2rem;
    font-weight: 600;
    text-align: justify;
    line-height: 1.2;
    margin-bottom: 1rem;
}
.container p {
    margin: 0 0 1rem 0;
    line-height: 1;
    font-size: 1rem;
}
// end here
.bg-dark .section-title p {
    max-width: 72ch;
    margin: 0 auto 30px;
    font-size: 1rem;
    line-height: 1.6;
    text-align: center;
    color: #fff;
}
