// @import "../../../../assets/variableStyles.scss";
// .results-card-block {
//     .results-sort-block {
//         display: flex;
//         align-items: center;
//         padding: 15px 0;
//         flex-wrap: wrap;
//         .sort-header {
//             color: #505c75;
//             font-size: 15px;
//             font-weight: 600;
//             margin-bottom: 0;
//         }
//         .sort-body {
//             display: flex;
//             align-items: center;
//             margin-bottom: 0;
//             li {
//                 margin: 0 10px;
//                 a {
//                     font-size: 15px;
//                     color: #969dac;
//                     @include mobile {
//                         font-size: 12px;
//                     }
//                 }
//             }
//             .active {
//                 a, span {
//                     color: #1c2b39;
//                     border-top: 2px solid #0775e2;
//                     padding: 5px 0;
//                     cursor: pointer;
//                 }
//             }
//         }
//     }
//     .card-results-block {
//         .card-results {
//             margin-bottom: 20px;
//             box-shadow: 0px 0px 2px 0px #969dac;
//         }
//         .card-results-travel-details {
//             padding: 15px;
//             .card-results-row {
//                 .card-results-columns {
//                     border-right: 1px solid #969dac;
//                     @include mobile {
//                         border: none;
//                     }
//                     .travels-name {
//                         h2 {
//                             color: #0775e2;
//                             font-size: 18px;
//                             @include mobile {
//                                 font-size: 15px;
//                                 font-weight: 600;
//                             }
//                         }
//                     }
//                     .selected-bus-type {
//                         color: #969dac;
//                         font-size: 12px;
//                     }
//                     &:last-child {
//                         border-right: none;
//                     }

//                 }
//                 .from-to-details {
//                     display: flex;
//                     .bus-timings {
//                         width: 20%;
//                         margin-right: 15px;
//                         @include mobile {
//                             position: absolute;
//                             top: -55px;
//                             right: 0;
//                         }
//                         ul {
//                             @include mobile {
//                                 display: flex;
//                                 flex-direction: column;
//                             }
//                             li {
//                                 margin-bottom: 5px;
//                                 text-align: end;
//                                 &:nth-child(1) {
//                                     color: #1c2b39;
//                                     font-size: 14px;
//                                 }
//                                 &:nth-child(2) {
//                                     color: #555;
//                                     font-size: 9px;
//                                     order: 1;
//                                 }
//                                 &:nth-child(3) {
//                                     color: #969dac;
//                                     font-size: 14px;
//                                 }
//                             }
//                         }
//                     }
//                     .address {
//                         width: 75%;
//                         margin-right: 15px;
//                         p {
//                             margin-bottom: 5px;
//                         }
//                         .onboard-address {
//                             white-space: nowrap;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                             font-size: 14px;
//                         }
//                         .available-boardingpoints {
//                             font-size: 12px;
//                             @include mobile {
//                                 padding-bottom: 10px;
//                             }
//                         }
//                     }
//                 }
//                 .available-price-details {
//                     text-align: end;
//                     @include mobile {
//                         display: flex;
//                         justify-content: space-between;
//                         flex-direction: row-reverse;
//                         border-top: 1px solid #969dac;
//                         padding-top: 10px;
//                         align-items: center;
//                     }
//                     .starting-price {
//                         margin-bottom: 10px;
//                         @include mobile {
//                             display: flex;
//                             margin-bottom: 0;
//                             align-items: center;
//                         }
//                         .starting-price-text {
//                             font-size: 13px;
//                             color: #969dac;
//                             margin-bottom: 0;
//                             @include mobile {
//                                 margin-right: 10px;
//                             }
//                         }
//                         .starting-price-number {
//                             font-size: 16px;
//                             font-weight: 600;
//                             @include mobile {
//                                 margin-bottom: 0;
//                             }
//                         }
//                     }
//                     .no-of-seats {
//                         span {
//                             font-size: 14px;
//                             color: #1c2b39;
//                         }
//                     }
//                 }
//             }
//         }
//         .card-results-tabs-info {
//             .cards-tabs-block {
//                 .nav-tabs {
//                     background-color: rgba(0,0,0,.03);
//                     justify-content: flex-end;
//                     margin: 0;
//                     .nav-link {
//                         color: #1c2b39;
//                         border: none;
//                         &:hover {
//                             border: none;
//                         }
//                         &:last-child {
//                             background-color: #ea1b37;
//                             color: #fff;
//                             border-radius:0;
//                             font-weight: 600;
//                             &:active {
//                                 background-color: #ea1b37;
//                                 color: #fff;
//                                 border-bottom: none;
//                             }
//                         }

//                     }
//                     .nav-link.active {
//                         color: #ea1b37;
//                         border-color: transparent;
//                         background-color: transparent;
//                         border-bottom: 1px solid #ea1b37;
//                         &:last-child {
//                             background-color: #ea1b37;
//                             color: #fff;
//                             border-bottom: none;
//                         }
//                     }
//                 }
//                 .amenities-tab {
//                     padding: 15px;
//                     .aminities-tab-header {
//                         h4 {
//                             font-size: 16px;
//                             font-weight: 600;
//                         }
//                     }
//                     .aminities-tab-body {
//                         ul {
//                             margin-bottom: 0;
//                             li {
//                                 display: flex;
//                                 p {
//                                     width: 50%;
//                                     margin-right: 70px;
//                                     padding: 10px;
//                                     margin-bottom: 5px;
//                                     border-bottom: 1px solid #969dac;
//                                     font-size: 14px;
//                                     i {
//                                         margin-right: 15px;
//                                         color: #969dac;
//                                         font-size: 20px;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 .boarding-dropping-points-tab {
//                     padding: 15px;
//                     .boarding-dropping-points-tab-container {
//                         display: flex;
//                         flex-wrap: wrap;
//                         .boarding-points-block {
//                             width: 50%;
//                             .boarding-points-block-header {
//                                 h4 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                             }
//                             .boarding-points-block-body {
//                                 max-height: 200px;
//                                 overflow-y: scroll;
//                                 ul {
//                                     margin-bottom: 0;
//                                     li {
//                                         padding: 10px 0;
//                                         border-bottom: 1px solid #969dac;
//                                         span {
//                                             font-size: 14px;
//                                             &:nth-child(1) {
//                                                 margin-right: 35px;
//                                             }
//                                             &:nth-child(2) {
//                                                 color: #969dac;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                         .dropping-points-block {
//                             width: 50%;
//                             .dropping-points-block-header {
//                                 h4 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                             }
//                             .dropping-points-block-body {
//                                 max-height: 200px;
//                                 overflow-y: scroll;
//                                 padding-left: 10px;
//                                 ul {
//                                     margin-bottom: 0;
//                                     li {
//                                         padding: 10px 0;
//                                         border-bottom: 1px solid #969dac;
//                                         span {
//                                             font-size: 14px;
//                                             &:nth-child(1) {
//                                                 margin-right: 35px;
//                                             }
//                                             &:nth-child(2) {
//                                                 color: #969dac;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 .cancllation-policy-tab {
//                     padding: 15px;
//                     .cancellation-policy-container {
//                         display: flex;
//                         flex-wrap: wrap;
//                         padding: 15px;
//                         .cancellation-block {
//                             width: 50%;
//                             border-right: 1px  dashed #555;
//                             padding-right: 15px;
//                             .cancellation-block-header {
//                                 border-bottom: 1px solid #555;
//                                 h4 {
//                                     font-size: 16px;
//                                     font-weight: 600;
//                                 }
//                             }
//                             .cancellation-block-body {
//                                 ul {
//                                     margin-bottom: 0;
//                                     li {
//                                         padding: 10px 0;
//                                         border-bottom: 1px solid #969dac;
//                                         display: flex;
//                                         justify-content: space-between;
//                                         span {
//                                             font-size: 14px;
//                                         }
//                                         &:nth-child(1) {
//                                             font-weight: 600;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 .close-btn {
//                     padding: 0px 5px;
//                     border: 1px solid #555;
//                     border-radius: 25px;
//                     width: 70px;
//                     margin-left: auto;
//                     font-size: 14px;
//                     i {
//                         margin-left: 10px;
//                     }
//                 }
//             }

//         /*********** boarding-dropping-point-block **********/
//         .seat-selection-block {
//             .boarding-dropping-point-block{
//                 border: 1px solid #bfb2b2;
//                 box-shadow: 0 0 1px #000;
//                 margin: 10px 0;
//                 .boarding-dropping-point-block-inner {
//                     .boarding-dropping-point-block-tabs {
//                         .nav-tabs {
//                             background-color: transparent;
//                             justify-content: space-between;
//                             a {
//                                 width: 50%;
//                                 border-bottom: 1px solid #505c75;
//                                 text-align: center;
//                                 &:last-child {
//                                     background-color: transparent;
//                                     color: #000;
//                                     font-weight: normal;
//                                 }
//                             }
//                             .nav-link.active {
//                                 color: #ea1b37;
//                                 border-bottom: 2px solid #ea1b37;
//                             }
//                         }
//                         .boarding-points-web,.dropping-points-web {
//                             ul {
//                                 padding: 15px;
//                                 li {
//                                     display: flex;
//                                     margin-bottom: 10px;
//                                     .boarding-point-checkbox,.dropping-point-checkbox {
//                                         margin-right: 15px;
//                                         width: 5%;
//                                     }
//                                     .boarding-point-address,.dropping-point-address {
//                                         width: 75%;
//                                         h5 {
//                                             margin-bottom: 0;
//                                             font-size: 14px;
//                                             font-weight: 600;
//                                         }
//                                         p {
//                                             margin-bottom: 0;
//                                             font-size: 14px;
//                                             display: none;
//                                         }
//                                     }
//                                     .boarding-point-time,.dropping-point-time {
//                                         width: 20%;
//                                     }
//                                 }
//                             }
//                         }
//                         .selected-seat-fare-details {
//                             padding: 15px;
//                             ul {
//                                 li {
//                                     display: flex;
//                                     justify-content: space-between;
//                                     &:last-child {
//                                         border-top: 1px solid #000;
//                                         margin-top: 10px;
//                                         p {
//                                             margin-top: 10px;
//                                         }
//                                     }
//                                 }
//                             }
//                             .continue-booked-seat-btn {
//                                 text-align: center;
//                                 button {
//                                     background-color: #ea1b37;
//                                     color: #fff;
//                                     border-radius: 5px;
//                                     padding: 10px 40px;
//                                     border: none;
//                                     font-size: 16px;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//         }
//     }
// }
// /*************** seat layout styles **********/
// .empty-row {
//     padding: 5px;
// }
// .seat-selection-block {
//     padding: 15px;
//     .seat-layout-outer {
//         margin: 15px;
//         border: 1px solid #1c2b39;
//         padding: 15px;
//         border-left: 5px solid #1c2b39;
//         display: flex;
//         .seat-layout-title-block {
//             .seat-layout-title-block-inner-data {
//                 display: flex;
//                 flex-direction: column;
//                 height: 100%;
//                 align-items: center;
//                 border-right: 1px solid #1c2b39;
//                 margin-right: 15px;
//                 justify-content: space-between;
//             }
//             .seat-layout-title-text {
//                 p {
//                     margin-bottom: 15px;
//                     transform: rotate(270deg);
//                 }
//             }
//         }
//         .seater-seating-arrangement {
//             height: 100%;
//             width: 100%;
//             margin-left: 50px;
//             @include mobile {
//                 margin-left: 0;
//             }
//             .inner-seating-container {
//                 width: 100%;
//                 ul {
//                     display: flex;
//                     li {
//                         margin-right: 5px;
//                         .seat-layout-container {
//                             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
//                             height: 18px;
//                             width: 19px;
//                             border-radius: .3rem;
//                         }
//                     }
//                 }
//             }
//             .inner-sleeper-container {
//                 display: flex;
//                 justify-content: start;
//                 ul {
//                     display: flex;
//                     li {
//                         margin-right: 10px;
//                         .sleeper-seat {
//                             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px 0;
//                             height: 19px;
//                             width: 42px;
//                             border-radius: .3rem;
//                             cursor: pointer;
//                         }
//                         .booked-sleeper-type-image {
//                             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -40px;
//                             height: 19px;
//                             width: 42px;
//                             border-radius: .3rem;
//                         }
//                         .selected-sleeper-type-image {
//                             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -60px;
//                             height: 19px;
//                             width: 42px;
//                             border-radius: .3rem;
//                             cursor: pointer;
//                         }
//                         .reserved-ladies-sleeper-type-image {
//                             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -19px;
//                             height: 19px;
//                             width: 42px;
//                             border-radius: .3rem;
//                             cursor: pointer;
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     .sleeper-layout-outer {
//         margin: 15px;
//         border: 1px solid #1c2b39;
//         padding: 15px;
//         border-left: 5px solid #1c2b39;
//         display: flex;
//         .seat-layout-title-block {
//             .seat-layout-title-block-inner-data {
//                 display: flex;
//                 flex-direction: column;
//                 height: 100%;
//                 justify-content: flex-end;
//                 border-right: 1px solid #1c2b39;
//                 margin-right: 15px;
//             }
//             .seat-layout-title-text {
//                 p {
//                     margin-bottom: 15px;
//                     transform: rotate(270deg);
//                 }
//             }
//         }
//         .seater-seating-arrangement {
//             height: 100%;
//             width: 100%;
//             margin-left: 50px;
//             @include mobile {
//                 margin-left: 0;
//             }
//             .inner-seating-container {
//                 width: 100%;
//                 ul {
//                     display: flex;
//                     li {
//                         margin-right: 5px;
//                         .seat-layout-container {
//                             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
//                             height: 18px;
//                             width: 19px;
//                             border-radius: .3rem;
//                         }
//                     }
//                 }
//             }
//             .inner-sleeper-container {
//                 ul {
//                     display: flex;
//                     li {
//                         margin-right: 10px;
//                         .sleeper-seat {
//                             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px 0;
//                             height: 19px;
//                             width: 42px;
//                             border-radius: .3rem;
//                             cursor: pointer;
//                         }
//                         .booked-sleeper-type-image {
//                             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -40px;
//                             height: 19px;
//                             width: 42px;
//                             border-radius: .3rem;
//                         }
//                         .selected-sleeper-type-image {
//                             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -60px;
//                             height: 19px;
//                             width: 42px;
//                             border-radius: .3rem;
//                             cursor: pointer;
//                         }
//                         .reserved-ladies-sleeper-type-image {
//                             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -19px;
//                             height: 19px;
//                             width: 42px;
//                             border-radius: .3rem;
//                             cursor: pointer;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// /*********** Seat Type Description **********/
// .seat-type-description {
//     ul {
//         display: flex;
//         justify-content: space-between;
//         li {
//             text-align: center;
//             .available-seat-type-image {
//                 background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
//                 height: 18px;
//                 width: 20px;
//                 border-radius: .3rem;
//                 cursor: pointer;
//             }
//             .Booked-seat-type-image {
//                 background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) 0 -40px;
//                 height: 18px;
//                 width: 20px;
//                 border-radius: .3rem;
//             }
//             .selected-seat-type-image {
//                 background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) 0 -60px;
//                 height: 18px;
//                 width: 20px;
//                 border-radius: .3rem;
//                 cursor: pointer;
//             }
//             .ladies-reserved-seat-type-image {
//                 background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -21px -20px;
//                 height: 18px;
//                 width: 20px;
//                 border-radius: .3rem;
//                 cursor: pointer;
//             }
//             p {
//                 margin-bottom: 0;
//                 text-align: center;
//                 color: #757575;
//                 font-size: .8rem;
//             }
//         }
//     }
// }

// .vertical_seat{
//     transform: rotate(-90deg);
// }
// .no-pointer-events{
//     pointer-events: none;
// }

@import "../../../../assets/variableStyles.scss";
.results-card-block {
  .results-sort-block {
    display: flex;
    align-items: center;
    padding: 4px;
    flex-wrap: wrap;
    background-color: #ffffff !important;
    border-radius: 8px;
    box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
    border: 0px solid #f0f0f0 !important;
    margin-bottom: 20px;

    .sort-header {
      color: #505c75;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
      margin-right: 10px;
    }
    .sort-body {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      li {
        margin: 0 10px;
        a {
          font-size: 15px;
          color: #969dac;
          @include mobile {
            font-size: 12px;
          }
        }
      }
      .active {
        a,
        span {
          color: #1c2b39;
          border-top: 2px solid #0775e2;
          padding: 5px 0;
          cursor: pointer;
        }
      }
    }
  }
  .card-results-block {
    // width: 100%;
    // height: 100%;
    // padding-right: 2px;
    // overflow: hidden;
    // ::-webkit-scrollbar {
    //   display: none;
    // }
    // .resultsScrollWrapper {
    //   width: 100%;
    //   height: 100%;
    //   overflow-y: scroll;
    //   padding-right: 18px;
    //   box-sizing: content-box;
    //   max-height: 630px;
    // }
    .edit-onward {
      margin-bottom: 15px;
      .returntext {
        color: #d5436a;
        font-weight: 600;
      }
      .ant-card-body {
        padding: 15px;
      }
      .box_area {
        display: flex;
        .onward_wrapper {
          padding-right: 4px;
          border-right: 2px solid #eee;
          color: #d5436a;
          p {
            font-weight: 600;
            margin-bottom: 3px;
            font-size: 14px;
          }
        }
        .bus-details {
          padding-left: 4px;
        }
      }
      .onward-bus-name {
        font-size: 16px;
        color: #0775e2 !important;
        margin-bottom: 3px;
        font-weight: 600;
      }
      .onward-bus-type,
      .onward-boarding-point,
      .edit-timimg-list {
        font-size: 12px;
        margin-bottom: 0;
        font-weight: 600;
        color: #969dac;
      }

      .onward-price {
        li {
          font-weight: 600;
          &:nth-child(2) {
            font-size: 12px;
            font-weight: 600;
            color: #969dac;
          }
        }
      }
      .tg-primary-btn {
        width: auto;
        font-size: 12px;
      }
    }
    .card-results {
      margin-bottom: 16px;

      border-radius: 8px !important;
      border: 0px;
      background-color: rgb(255, 255, 255);

      box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
    }
    .card-results-travel-details {
      padding: 15px;
      .card-results-row {
        .card-results-columns {
          border-right: 0px solid #969dac;
          @include mobile {
            border: none;
          }
          .travels-name {
            h2 {
              color: #0775e2;
              font-size: 18px;
              @include mobile {
                font-size: 16px;
                font-weight: 600;
              }
            }
          }
          .selected-bus-type {
            font-size: 11px;
            color: #969dac;
            margin-bottom: 0;
            font-weight: 400;
          }
          &:last-child {
            border-right: none;
          }
        }
        .from-to-details {
          display: block;
          .journeyDate {
            color: #1c2b39;
            font-size: 16px;
            font-weight: 600;
          }
          .bus-timings {
            ul {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .du-fast {
                border-top: 2px solid #0775e2;
                margin: 0 5px;
                padding-bottom: 5px;
                position: relative;
                flex: 1;
                color: #555;
                font-size: 10px;
                font-weight: 600;
                span {
                  position: absolute;
                  top: -7px;
                  left: 0;
                  right: 0;
                  width: fit-content;
                  margin: 0 auto;
                  background: white;
                  padding: 0 5px;
                }
              }

              li {
                margin-bottom: 5px;
                text-align: start;
                display: inline-block;
                color: #1c2b39;
                font-size: 18px;
                font-weight: 700;
                @include mobile {
                  font-size: 16px;
                  font-weight: 600;
                }
              }
            }
            .arrivaldate {
              font-weight: 600;
              text-align: right;
              color: #d5436a;
            }
          }
          .address {
            width: 100%;
            margin-right: 15px;
            display: block;
            @include mobile {
              display: block;
            }
            p {
              margin-bottom: 5px;
            }
            .onboard-address {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 11px;
              color: #969dac;
              margin-bottom: 0;
              font-weight: 400;
            }
            .available-boardingpoints {
              font-size: 11px;
              color: #969dac;
              margin-bottom: 0;
              font-weight: 400;
              @include mobile {
                padding-bottom: 10px;
                padding-left: 0%;
              }
            }
          }
        }
        .available-price-details {
          text-align: right;
          @include mobile {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #969dac;
            padding-top: 10px;
            align-items: center;
          }
          .starting-price {
            margin-bottom: 5px;
            @include mobile {
              margin-bottom: 0;
              text-align: start;
              flex: 1;
            }
            .starting-price-text {
              font-size: 11px;
              color: #969dac;
              margin-bottom: 0;
              font-weight: 400;
            }
            .starting-price-number {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 0;
              @include mobile {
                font-size: 16px;
              }
              .comm-text {
                font-size: 12px;
              }
            }
          }
          .no-of-seats {
            span {
              font-size: 11px;
              color: #969dac;
              margin-bottom: 0;
              font-weight: 400;
            }
          }
        }
      }
    }
    .card-results-tabs-info {
      .cards-tabs-block {
        .nav-tabs {
          background-color: rgba(0, 0, 0, 0.03);
          justify-content: flex-start;
          margin: 0;
          @include mobile {
            justify-content: start;
          }
          .tab-content {
            .container-fluid {
              padding: 0;
            }
          }
          .nav-link {
            color: #1c2b39;
            border: none;
            font-size: 12px;
            font-weight: 600;
            @include mobile {
              display: inline-flex;
              font-size: 12px;
            }
            &:hover {
              border: none;
            }
            &:last-child {
              background-color: #ea1b37;
              color: #fff;
              border-radius: 0;
              font-weight: 600;
              display: block;
              margin-left: auto;
              border-radius: 4px;
              &:active {
                background-color: #ea1b37;
                color: #fff;
                border-bottom: none;
              }
            }
          }
          .nav-link.active {
            color: #d5436a;
            border-color: transparent;
            background-color: transparent;
            border-bottom: 1px solid #ea1b37;
            &:last-child {
              background-color: #ea1b37;
              color: #fff;
              border-bottom: none;
            }
            @include mobile {
              border-bottom: none;
            }
          }
        }
        .amenities-tab {
          padding: 15px;
          .aminities-tab-header {
            h4 {
              font-size: 16px;
              font-weight: 600;
            }
          }
          .aminities-tab-body {
            ul {
              margin-bottom: 0;
              li {
                display: flex;
                p {
                  width: 50%;
                  margin-right: 70px;
                  padding: 10px;
                  margin-bottom: 5px;
                  border-bottom: 1px solid #969dac;
                  font-size: 14px;
                  font-weight: 700;

                  i {
                    margin-right: 15px;
                    color: #969dac;
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }
        .boarding-dropping-points-tab {
          padding: 15px;
          .close-btn {
            text-align: right;
            padding-bottom: 10px;
            span {
              margin-right: 5px;
            }
          }
          .boarding-dropping-points-tab-container {
            border: 2px solid #f7f7f7;
            border-radius: 10px;
            display: flex;
            flex-wrap: wrap;
            @include mobile {
              flex-direction: column;
            }
            .boarding-points-block {
              width: 50%;
              padding: 15px;
              border-right: 2px solid #f7f7f7;
              @include mobile {
                width: 100%;
                border-right: none;
              }
              ::-webkit-scrollbar {
                width: 4px;
              }
              ::-webkit-scrollbar-track {
                background-color: #f7f7f7;
                border-radius: 16px;
              }
              ::-webkit-scrollbar-thumb {
                background-color: #d8d8d8;
                border-radius: 16px;
              }
              ::-webkit-scrollbar-button {
                display: none;
              }
              .boarding-points-block-header {
                h4 {
                  font-size: 12px;
                  font-weight: 600;
                  color: #969dac;
                }
              }
              .boarding-points-block-body {
                max-height: 200px;
                overflow-y: scroll;
                @include mobile {
                  max-height: 140px;
                }
                ul {
                  margin-bottom: 0;
                  li {
                    padding-top: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .dot {
                      width: 5px;
                      height: 5px;
                      border: 1px solid #a4a4a4;
                      display: inline-block;
                      border-radius: 50%;
                      margin-right: 15px;
                    }
                    span {
                      font-size: 11px;
                      font-weight: 500;
                      color: #969dac;
                      // .pointTooltip {
                      //   overflow: hidden;
                      //   text-overflow: ellipsis;
                      //   white-space: nowrap;
                      // }
                      &:nth-child(2) {
                        margin-right: 15px;
                      }
                    }
                  }
                }
              }
            }
            .dropping-points-block {
              width: 50%;
              padding: 15px;
              @include mobile {
                width: 100%;
                padding-top: 0;
              }
              ::-webkit-scrollbar {
                width: 4px;
              }
              ::-webkit-scrollbar-track {
                background-color: #f7f7f7;
                border-radius: 16px;
              }
              ::-webkit-scrollbar-thumb {
                background-color: #d8d8d8;
                border-radius: 16px;
              }
              ::-webkit-scrollbar-button {
                display: none;
              }
              .dropping-points-block-header {
                h4 {
                  font-size: 12px;
                  font-weight: 600;
                  color: #969dac;
                }
              }
              .dropping-points-block-body {
                max-height: 200px;
                overflow-y: scroll;
                @include mobile {
                  max-height: 140px;
                }
                ul {
                  margin-bottom: 0;
                  li {
                    padding-top: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .dot {
                      width: 5px;
                      height: 5px;
                      border: 1px solid #a4a4a4;
                      display: inline-block;
                      border-radius: 50%;
                      margin-right: 15px;
                    }
                    span {
                      font-size: 12px;
                      font-weight: 500;
                      color: #969dac;
                      &:nth-child(2) {
                        margin-right: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .cancllation-policy-tab {
          padding: 15px;
          .close-btn {
            text-align: right;
            padding-bottom: 10px;
            span {
              margin-right: 5px;
            }
          }
          .cancellation-policy-container {
            display: flex;
            flex-wrap: wrap;

            .cancellation-block {
              flex: 1;
              margin: 0;
              .cancellation-block-header {
                h4 {
                  font-size: 12px;
                  font-weight: 600;
                }
              }
              .cancellation-block-body {
                ul {
                  margin-bottom: 0;

                  border: 2px solid #f7f7f7;
                  border-radius: 10px;
                  li {
                    display: flex;
                    justify-content: space-between;
                    span {
                      width: 50%;
                      padding: 8px 15px;
                      border-top: 2px solid #f7f7f7;
                      border-right: 2px solid #f7f7f7;
                      font-size: 12px;
                      font-weight: 500;
                    }
                    .title {
                      border-top: 0;
                      font-weight: 600;
                    }
                    .last {
                      border-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
        .pricefilter_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .pricefilter {
            display: flex;
            align-items: center;
            .ant-btn {
              border-radius: 20px;
              height: auto;
              margin-left: 15px;
              @include mobile {
                font-size: 12px;
                margin-left: 5px;
                padding: 2px 8px;
              }
              .seatcount {
                font-size: 12px;
                margin-left: 3px;
                @include mobile {
                  font-size: 10px;
                  margin-left: 3px;
                }
              }
            }
            .active {
              color: #fff;
              background-color: #ea1b37;
              border-color: #ea1b37;
            }
          }
          .close-btn {
            // padding: 0px 5px;
            // border: 1px solid #555;
            // border-radius: 25px;
            // width: 70px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            @include mobile {
              font-size: 14px;
            }
            // i {
            //   margin-left: 10px;
            // }
          }
        }
      }

      .seats_container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .boarding-dropping-point-block {
          flex: 1;
          max-width: 450px;
          overflow: visible;
          ::-webkit-scrollbar {
            display: block !important;
            width: 8px;
          }
          ::-webkit-scrollbar-track {
            background-color: #f7f7f7;
            border-radius: 16px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #d8d8d8;
            border-radius: 10px;
          }
          ::-webkit-scrollbar-button {
            display: none;
          }
          @include tab {
            max-width: 100%;
          }
        }
        @include tab {
          flex-direction: column;
        }
      }

      /*********** boarding-dropping-point-block **********/
      .seat-selection-block {
        .boarding-dropping-point-block {
          box-shadow: 0 0 1px #000;
          margin: 10px 0;
          @include tab {
            padding: 0 10px;
          }
          .boarding-dropping-point-block-inner {
            .boarding-dropping-point-block-tabs {
              .ant-form {
                padding: 10px;
                .ant-form-item {
                  margin-bottom: 5px;
                }
                .ant-select-selection-item {
                  .pointTooltip {
                    white-space: normal !important;
                  }
                }
              }
              .nav-tabs {
                background-color: transparent;
                justify-content: space-between;
                a {
                  width: 50%;
                  border-bottom: 1px solid #505c75;
                  text-align: center;
                  &:last-child {
                    background-color: transparent;
                    color: #000;
                    font-weight: normal;
                  }
                }
                .nav-link.active {
                  color: #d5436a;
                  border-bottom: 2px solid #d5436a;
                }
              }
              .boarding-points-web,
              .dropping-points-web {
                ul {
                  padding: 15px;
                  li {
                    display: flex;
                    margin-bottom: 10px;
                    .boarding-point-checkbox,
                    .dropping-point-checkbox {
                      margin-right: 15px;
                      width: 5%;
                    }
                    .boarding-point-address,
                    .dropping-point-address {
                      width: 75%;
                      h5 {
                        margin-bottom: 0;
                        font-size: 14px;
                        font-weight: 600;
                      }
                      p {
                        margin-bottom: 0;
                        font-size: 14px;
                        display: none;
                      }
                    }
                    .boarding-point-time,
                    .dropping-point-time {
                      width: 20%;
                    }
                  }
                }
              }
              .selected-seat-fare-details {
                padding: 15px;
                ul {
                  li {
                    display: flex;
                    justify-content: space-between;
                    &:last-child {
                      border-top: 1px solid #000;
                      margin-top: 10px;
                      p {
                        margin-top: 10px;
                      }
                    }
                  }
                }
                .continue-booked-seat-btn {
                  text-align: center;
                  button {
                    height: auto;
                    background-color: #ea1b37;
                    color: #fff;
                    border-radius: 5px;
                    padding: 10px 40px;
                    border: none;
                    font-size: 16px;
                    &:disabled {
                      background-color: #da520085;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/*************** seat layout styles **********/
.empty-row {
  padding: 5px;
}
.seats-layout-block {
  width: fit-content;
}

.seat-selection-block {
  padding: 15px;
  @include mobile {
    padding: 15px 10px;
  }
  .seat-layout-outer {
    margin: 10px 10px 10px 0;
    border: 1px solid #1c2b39;
    padding: 10px 0;
    border-left: 5px solid #1c2b39;
    display: flex;
    @include mobile {
      border-left: 2px solid #1c2b39;
    }
    .seat-layout-title-block {
      .seat-layout-title-block-inner-data {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        border-right: 1px solid #1c2b39;
        margin-right: 15px;
        justify-content: space-between;
        padding-bottom: 10px;
        @include mobile {
          margin-right: 0px;
          font-size: 10px;
        }
      }
      .seat-layout-title-text {
        p {
          margin-bottom: 0;
          transform: rotate(270deg);
        }
      }
    }
    .seater-seating-arrangement {
      height: 100%;
      display: flex;
      flex-direction: column;

      @include mobile {
        margin-left: 0;
      }
      // .inner-seating-container {
      //   width: 100%;
      ul {
        display: flex;
        justify-content: flex-start;

        li {
          margin-right: 5px;
          .seat-layout-container {
            background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
            height: 18px;
            width: 19px;
            border-radius: 0.3rem;
          }
        }
      }
      // }
      // .inner-sleeper-container {
      ul {
        display: flex;
        justify-content: flex-start;

        li {
          margin-right: 5px;
          @include mobile {
            margin: 2px !important;
          }

          .sleeper-seat {
            //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px
            //     0;
            background-color: #d9d9d9;
            height: 19px;
            width: 40px;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            @include mobile {
              width: 34px;
            }
          }
          .active {
            border: 1px solid #010052;
          }

          .booked-sleeper-type-image {
            //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -40px;
            background-color: #0ed145;
            height: 19px;
            width: 40px;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: #fff;
          }
          .selected-sleeper-type-image {
            //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -60px;
            background-color: #010052;
            height: 19px;
            width: 40px;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
          }
          .reserved-ladies-sleeper-type-image {
            //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -19px;
            background-color: #f2bfbe;
            height: 19px;
            width: 40px;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
          }
          .reserved-ladiesRes-sleeper-type-image {
            //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -19px;
            border: 2px solid #f2bfbe;
            background-color: #d9d9d9;
            display: grid;
            place-content: center;
            height: 19px;
            width: 40px;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
          }
        }
      }
      // }
    }
  }
  .sleeper-layout-outer {
    margin: 10px 10px 10px 0;
    border: 1px solid #1c2b39;
    padding: 10px 0;
    border-left: 5px solid #1c2b39;
    display: flex;
    @include mobile {
      border-left: 2px solid #1c2b39;
    }
    .seat-layout-title-block {
      padding: 2px;
      .seat-layout-title-block-inner-data {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        border-right: 1px solid #1c2b39;
        margin-right: 15px;
        padding-bottom: 10px;
        @include mobile {
          margin-right: 0px;
          font-size: 10px;
        }
      }
      .seat-layout-title-text {
        p {
          margin-bottom: 0;
          transform: rotate(270deg);
        }
      }
    }
    .seater-seating-arrangement {
      height: 100%;
      display: flex;
      flex-direction: column;
      ul {
        display: flex;
        justify-content: flex-start;

        li {
          margin: 5px;
          text-align: center;
          @include mobile {
            margin: 2px !important;
          }
          .seat-layout-container {
            background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
            height: 18px;
            width: 19px;
            border-radius: 0.3rem;
          }
        }
      }
      // }
      // .inner-sleeper-container {
      ul {
        display: flex;
        li {
          margin: 5px;
          text-align: center;
          .sleeper-seat {
            //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px
            //     0;
            background-color: #d9d9d9;
            height: 19px;
            width: 40px;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            @include mobile {
              width: 32px;
              font-size: 10px;
              font-weight: normal;
            }
          }
          .active {
            border: 1px solid #010052;
          }
          .booked-sleeper-type-image {
            //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -40px;
            background-color: #0ed145;
            height: 19px;
            width: 40px;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: #fff;
            @include mobile {
              width: 32px;
              font-size: 10px;
              font-weight: normal;
            }
          }
          .selected-sleeper-type-image {
            //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -60px;
            background-color: #010052;
            height: 19px;
            width: 40px;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            @include mobile {
              width: 32px;
              font-size: 10px;
              font-weight: normal;
            }
          }
          .reserved-ladies-sleeper-type-image {
            //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -19px;
            background-color: #f2bfbe;
            height: 19px;
            width: 40px;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            @include mobile {
              width: 32px;
              font-size: 10px;
              font-weight: normal;
            }
          }
          .available-seat-type-image {
            // background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
            background-color: #d9d9d9;
            height: 18px;
            width: 25px;
            margin: 0 auto;
            border-radius: 5px 0 0 5px;
            font-size: 11px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            @include mobile {
              width: 20px;
              font-weight: normal;
              font-size: 10px;
            }
            @include xsmobile {
              width: 18px;

              font-size: 8px;
            }
          }
          .selected-seat-type-image {
            // background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif)
            //   0 -60px;
            background-color: #010052;
            height: 18px;
            width: 25px;
            margin: 0 auto;
            border-radius: 5px 0 0 5px;
            font-size: 11px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            @include mobile {
              width: 20px;
              font-weight: normal;
              font-size: 10px;
            }
            // @include xsmobile {
            //   width: 18px;

            //   font-size: 8px;
            // }
          }
        }
      }
      // }
    }
  }
}

/*********** Seat Type Description **********/
.seat-type-description {
  ul {
    display: flex !important;
    justify-content: flex-start;

    li {
      text-align: center;
      margin: 5px;
      .available-seat-type-image {
        // background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
        background-color: #d9d9d9;
        height: 18px;
        width: 25px;
        margin: 0 auto;
        border-radius: 5px 0 0 5px;
        font-size: 11px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        @include mobile {
          width: 20px;
          font-weight: normal;
          font-size: 10px;
        }
        @include xsmobile {
          width: 18px;

          font-size: 8px;
        }
      }
      .active {
        border: 1px solid #010052;
      }
      .Booked-seat-type-image {
        // background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif)
        //   0 -40px;
        background-color: #0ed145;
        height: 18px;
        width: 25px;
        margin: 0 auto;
        border-radius: 5px 0 0 5px;
        font-size: 11px;
        font-weight: 600;
        color: #fff;
        @include mobile {
          width: 20px;
          font-weight: normal;
          font-size: 10px;
        }
        // @include xsmobile {
        //   width: 18px;

        //   font-size: 8px;
        // }
      }
      .selected-seat-type-image {
        // background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif)
        //   0 -60px;
        background-color: #010052;
        height: 18px;
        width: 25px;
        margin: 0 auto;
        border-radius: 5px 0 0 5px;
        font-size: 11px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        @include mobile {
          width: 20px;
          font-weight: normal;
          font-size: 10px;
        }
        // @include xsmobile {
        //   width: 18px;

        //   font-size: 8px;
        // }
      }
      .ladies-reserved-seat-type-image {
        // background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -21px -20px;
        background-color: #f2bfbe;

        height: 18px;
        width: 25px;
        margin: 0 auto;
        border-radius: 5px 0 0 5px;
        font-size: 11px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        @include mobile {
          width: 20px;
          font-weight: normal;
          font-size: 10px;
        }
        // @include xsmobile {
        //   width: 18px;

        //   font-size: 8px;
        // }
      }
      .ladiesRes-reserved-seat-type-image {
        // background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -21px -20px;
        border: 2px solid #f2bfbe;
        background-color: #d9d9d9;
        display: grid;
        place-content: center;
        height: 18px;
        width: 25px;
        margin: 0 auto;
        border-radius: 5px 0 0 5px;
        font-size: 11px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        @include mobile {
          width: 20px;
          font-weight: normal;
          font-size: 10px;
        }
        // @include xsmobile {
        //   width: 18px;

        //   font-size: 8px;
        // }
      }
      p {
        margin-bottom: 0;
        text-align: center;
        color: #757575;
        font-size: 0.8rem;
      }
    }
  }
  .space_between {
    justify-content: space-evenly;
  }
}

.vertical_seat {
  transform: rotate(-90deg);
  padding-top: 0px;
  width: 18px;
}
.no-pointer-events {
  pointer-events: none;
}

/* 04-12-2020-css */

.search-point-boarding {
  display: flex;
}
.boarding-point-search {
  padding-left: 10px;
}
.search-point-boarding h4 {
  padding-top: 5px;
}
.boarding-point-search .form-control {
  height: 30px !important;
  width: 64%;
}

@media screen and (max-width: 768px) {
  .search-point-boarding {
    display: block;
  }
  .search-point-boarding h4 {
    padding-top: 0px;
  }
  .boarding-point-search {
    padding-left: 0px;
  }
  .block-of-info-bus .ant-collapse-header {
    display: block !important;
    font-weight: 700;
    font-size: 18px;
  }
  .block-of-info-bus .ant-select {
    line-height: 4.2 !important;
    border-right: 0px solid #d6d3d3 !important;
  }
  .results-card-block .results-sort-block {
    display: none;
  }
}

/*css-bus--results--*/
.modify-combined-block .ant-collapse {
  background: transparent;
  border: none;
}
.modify-combined-block {
  max-width: 1200px;
  margin: auto;
  padding: 4px 0;
}
.search-block .search-body {
  background-color: #fff !important;
  border-radius: 10px;
  padding: 8px 25px !important;
  position: relative;

  box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
}
.filters-number-tab {
  padding: 10px 0px;
  max-width: 1200px;
  margin: auto;
}

.block-of-info-bus .hotels_search_box {
  background-color: #fff !important;
  border-radius: 10px;
  padding: 8px 25px !important;
  position: relative;

  box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
}
.filters-number-tab .tab-content ul li {
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 5px 0;
  width: 100%;
  font-size: 15px;
  color: #676666;
  line-height: 15px;
  border-bottom: 0px dashed #dfe2e2;
}

.block-of-info-bus .ant-collapse-header {
  display: none;
}

.buses-div-bg {
  background-color: #2f81ed;
  background-image: linear-gradient(to right, #2f80ed, #56ccf2);
  padding-top: 0px;
  padding-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .results-card-block .card-results-block .card-results {
    margin: 10px 10px;
  }
  .search-point-boarding {
    display: block;
  }
  .search-point-boarding h4 {
    padding-top: 0px;
  }
  .boarding-point-search {
    padding-left: 0px;
  }
  ul.first-sleeper-row li {
    padding: 0px 8px 0px 0px !important;
  }
  .buses-div-bg {
    padding-bottom: 0px !important;
  }
}
.empty_seat_sleeper {
  height: 19px !important;
  width: 40px !important;
}
ul.first-sleeper-row li {
  padding: 0px 26px 0px 0px;
}
.buses-div-bg .card {
  padding: 0px 10px !important;
}

@media screen and (max-width: 768px) {
  .bus-res-cc2 {
    margin: 10px 10px;
  }
  .mobile-sort-22 {
    display: none;
  }
  .sort-bus-block-22 .ant-radio-group-outline {
    display: flex;
    flex-direction: column;
  }
  .sort-bus-block-22 .ant-radio-wrapper {
    border: none !important;
  }
  .sort-bus-block-22 .price-type {
    font-size: 14px !important;
    font-weight: 600;
  }
}
