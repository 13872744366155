@import "../../../assets/variableStyles.scss";

.trip-contents {
  // padding: 10px 15px;
  display: flex;
  justify-content: space-around;
  padding: 10px 0px;
  // padding-left: 50px;
  @include mobile {
    padding-left: 5px;
  }
  &:hover {
    background: var(--leg-hover-color, rgba(234, 235, 238, 0.6));
  }
  input {
    margin-right: 10px;
  }
}
// .airline-img {
//     max-width: 55px;
//     height: 28px;
// }
.trip-content-left {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @include mobile {
    flex-direction: column;
  }
}
.trip-arr-dep {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 20px;
  @include mobile {
    flex-direction: column;
  }
}

.timings {
  i {
    color: #1c2b39;
    font-size: 15px;
    padding: 1px 5px;
  }
}
.duration {
  padding-left: 20px;
  text-align: center;
  @include mobile {
    padding-left: 5px;
  }
  i {
    @include mobile {
      display: none;
    }
  }
}
.show-more-btn {
  padding: 4px 4px 0px 80px;
  @include mobile {
    padding: 4px 4px 0px 26px;
  }
  button {
    border: solid 1px #0775e2;
    color: #0775e2;
    line-height: 25px;
    font-size: 12px;
    font-weight: 700;
    &:hover {
      background: none;
      color: #0775e2;
      font-weight: 700;
    }
    i {
      color: #0775e2;
      font-weight: 700;
    }
  }
}
.share-section {
  font-size: 17px;
  color: #007baa;
  display: block;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-top: 3px;
  span {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.currencyType {
  color: #969dac;
  font-size: 15px;
  letter-spacing: 1px;
  padding-right: 4px;
  @include mobile {
    font-size: 15px;
  }
}
.netfare {
  font-size: 15px;
  letter-spacing: 1px;
  margin-bottom: 2px;
}
.different-amenities {
  font-size: 16px;
  color: rgb(29, 160, 35);
  // margin-left: 100px;
  // margin-left: 140px;
  @include mobile {
    // margin-left: 20px;
  }
}
/* Tooltip-styles start */
// .amenities-tool-tip {
//     @include mobile {
//         display: none;
//     }
// }
.ament-tooltip {
  padding-left: 40px;
}
.amenities-tooltip .amenities-tooltip > .tooltip-inner {
  // max-width: 100%;
  min-width: 450px !important;
  @include mobile {
    max-width: 300px !important;
  }
}
.ant-tooltip {
  max-width: auto !important;
  //   left: 200px !important;
  @include mobile {
    max-width: 300px !important;
  }
}
// .ant-tooltip-content {
//     min-width: 450px !important;
//     background: #0e0e0e !important;
//     @include mobile {
//         max-width: 300px !important;
//     }
// }

.tooltip-content {
  display: flex;
  justify-content: center;
  padding: 20px 20px !important;
  //   min-width: 450px !important;
  .flight-amenities {
    padding: 0px 5px;
    .flight-src-dest {
      h6 {
        color: #fff;
        padding-bottom: 10px;
      }
    }
    .amenity-types {
      p {
        line-height: 1.4;
        font-size: 12px;
        i {
          padding-right: 6px;
        }
      }
      .aminities-color {
        color: #1da023;
        font-size: 12px;
      }
    }
  }
}
/* Tooltip-styles end */

.trip-total-details {
  @include mobile {
    order: 1;
  }
}
.trip-choose-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-left: solid 1px #ccc;
  @include mobile {
    order: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-align: left;
    padding: 0px 30px;
  }
}

/*********** Antd styles ********/
.flight-results-container {
  // height: 100%;
  .ant-card-body {
    padding: 0;
  }
  .ant-row {
    @include mobile {
      flex-wrap: wrap-reverse;
    }
  }
  .flight-trip-details {
    border-right: 1px solid #ccc;
    @include mobile {
      border: none;
    }
    // padding: 15px;
    background-image: linear-gradient(180deg, #fafcfd, #fff);
    border-radius: 8px;
    .itinary-details {
      display: flex;
      .itinary-flight-name {
        width: 25%;
        @include mobile {
          width: 78%;
          margin-bottom: 12px;
        }

        img {
          width: 50px;
          @include mobile {
            width: 35px;
            align-items: center;
          }
        }
      }
      .fromTo {
        width: 50%;
        @include mobile {
          flex: 1;
          width: 100%;
          padding-top: 10px;
        }
        .cities-from-to {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // align-items: center;
          position: relative;
          @include mobile {
            width: 94%;
          }
          .loc_wrapper,
          .terminal_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 10px;
            font-weight: 600;
            @include mobile {
              p {
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }
  }
  .code-line {
    width: 50%;
    height: 2.4px;
    background: #0775e2;
    margin: auto;
    @include mobile {
      font-size: 12px;
      padding: 0px 15px;
      align-items: center;
      text-align: center;
      width: 10%;
    }
  }
  .flight-class-details {
    width: 25%;
    text-align: end;
    @include mobile {
      width: fit-content;
      position: absolute;
      top: 18px;
      right: 12px;
    }
  }
}

/******************* Flight Choose button section ***********/
.choose-btn-wrapper {
  background: #fff;
  margin-bottom: 10px;
  height: 100%;

  background-image: linear-gradient(180deg, #fafcfd, #fff);
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
  border-right: 1px solid #ccc;
}
.choose-btn-block {
  text-align: center;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include mobile {
    flex-direction: row;
    width: 100%;
    text-align: start;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 5px 10px;
  }
  .choose-btn-price {
    margin-bottom: 10px;
    @include mobile {
      margin-bottom: 0;
    }
  }
}
.choose-btn-section {
  @include mobile {
    order: 1;
  }
}
.btn-section {
  @include mobile {
    display: flex;
    .share-section {
      margin-right: 10px;
    }
  }
}
.choose-button {
  @include mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 23px;
  }
}

/******************* Flight Timings ***********/
.flight-timings {
  display: flex;
  align-items: center;
  margin-left: 0px;
  padding: 2px 0;
  justify-content: space-between;
  @include mobile {
    align-items: baseline;
  }
  .plane-timing-details {
    display: flex;
    width: 100%;
    @include mobile {
      flex-wrap: wrap;
    }
  }
  @include mobile {
    margin-left: 0;
  }
  .plane-timings {
    // width: 100%;
    display: flex;
    @include mobile {
      margin-bottom: 10px;
    }
    .timings-container {
      margin-right: 20px;
      @include mobile {
        margin-right: 0;
      }
    }
    ul {
      display: flex;
      align-items: center;
      li {
        margin-right: 5px;
        p {
          margin-bottom: 0;
          font-size: 13px;
          font-weight: bold;
          // @include mobile {
          //   font-size: 14px;
          // }
        }
      }
    }
  }
  .travel-time {
    display: flex;
    align-items: center;
    width: auto;
    p {
      margin-bottom: 0;
      margin-left: 5px;
      display: flex;
      align-items: center;
      color: #ccc;
      @include mobile {
        font-size: 12px;
      }
      span {
        margin-right: 5px;
        font-size: 20px;
        @include mobile {
          font-size: 15px;
          margin-right: 0;
        }
      }
    }
  }
  .facility-icons {
    width: 35%;
    span {
      display: flex;
      p {
        margin-right: 15px;
        margin-bottom: 0;
        color: #1da023;
        font-size: 20px;
        @include mobile {
          font-size: 15px;
        }
      }
    }
  }
  .details-btn {
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: flex-end;

    p {
      margin-bottom: 0;
      margin-left: 5px;
    }
    .details-text {
      font-size: 13px;
      color: #0775e2;
      margin-left: 15px;
      cursor: pointer;
    }
    .details-bag-icon {
      font-size: 20px;
      display: flex;
      align-items: center;
    }
  }
}
.flight-details-modal {
  width: 840px !important;
  .ant-modal-header {
    .ant-modal-title {
      color: white;
    }
  }
  .ant-modal-body {
    padding: 0;
    max-height: 500px;
    overflow-y: scroll;
  }
  .ant-modal-footer {
    display: none;
  }
  .all-times-local {
    padding: 16px 24px;
    border-bottom: 1px solid #ccc;
    @include mobile {
      text-align: center;
      padding: 0 15px 15px;
    }
    p {
      margin-bottom: 0;
    }
  }
  .from-to-modal-details {
    padding: 16px 24px;
    @include mobile {
      padding: 0;
      margin-bottom: 10px;
    }
  }
  .more-details-row {
    align-items: center;
    @include mobile {
      align-items: stretch;
      flex-direction: column;
    }
    .cities-names {
      @include mobile {
        width: 100%;
      }
    }
    .more-details-list {
      &:nth-child(2) {
        @include mobile {
          text-align: end;
        }
      }
      p {
        &:nth-child(1) {
          font-size: 25px;
          text-transform: uppercase;
          color: #00164d;
          margin-bottom: 0;
          @include mobile {
            font-size: 20px;
            span {
              display: block;
            }
          }
        }
        &:nth-child(2) {
          margin-bottom: 0;
          font-size: 12px;
          @include mobile {
            span {
              display: block;
            }
          }
        }
      }
    }
    .more-details-list-to {
      @include mobile {
        text-align: end;
      }
    }
    .more-details-line {
      height: 2px;
      background-color: #0775e2;
      margin: auto;
      margin-left: -15px;
      @include mobile {
        margin-left: 0;
      }
    }
    .trip-duration-time {
      @include mobile {
        display: flex;
        align-items: center;
        align-self: center;
      }
      p {
        &:nth-child(1) {
          color: #969dac;
          margin-bottom: 0;
          font-size: 12px;
          @include mobile {
            margin-right: 10px;
          }
        }
        &:nth-child(2) {
          color: #00164d;
          margin-bottom: 0;
        }
      }
    }
  }
  .total-flight-details {
    padding: 16px 24px 16px 60px;
    background-color: #f6f7f8;
    @include mobile {
      padding: 15px 15px 15px 22px;
    }
    .to-fro-flight-details {
      display: flex;
      position: relative;
      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }
      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 3px solid #0775e2;
        position: absolute;
        left: -33px;
        top: 4px;
        @include mobile {
          left: -16px;
        }
      }
      .from-city-flight-details {
        margin-right: 25px;
        @include mobile {
          display: flex;
          margin-right: 0;
        }
      }
    }
    .collapsed-card {
      padding: 25px 0;
      position: relative;
      @include mobile {
        padding: 10px 0;
      }
      &::before {
        content: "";
        height: 100%;
        position: absolute;
        background-color: #0775e2;
        width: 3px;
        left: -29px;
        top: 0;
        @include mobile {
          left: -12px;
        }
      }
      .ant-card-body {
        padding: 0;
      }
      .ant-collapse {
        border: none;
      }
      .ant-collapse-item {
        border: none;
      }
      .ant-collapse-header {
        background-color: white;
      }
      .ant-collapse-content-box {
        @include mobile {
          padding: 0 10px 10px;
        }
      }
    }
  }
}

.acc-body {
  .acc-body-section {
    display: flex;
    padding: 7px 2px;
    align-items: baseline;
    @include mobile {
      font-size: 12px;
    }
    .icon {
      margin-right: 7px;
      color: #1da023;
    }
    p {
      color: #1da023;
    }
    &:nth-child(1) {
      padding-right: 25px;
      @include mobile {
        padding-right: 0;
      }
    }
    .refundable {
      color: #1da023;
      padding-left: 12px;
    }
    .non-refundable {
      color: red;
      padding-left: 12px;
    }
  }
  .seat-sec {
    i {
      padding-right: 7px;
      color: #505c75;
    }
    p {
      color: #505c75;
    }
  }
  .flight-class {
    .ec-class {
      padding-left: 12px;
    }
    span {
      font-weight: 700;
    }
  }
}
.tooltip-data {
  h4 {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }
  p {
    font-size: 12px;
    color: #fff;
    margin-bottom: 0;
  }
}
.ant-tooltip-content {
  min-width: 100% !important;
  border-radius: 10px;
  .ant-tooltip-inner {
    padding: 10px 15px;
    // background-color: #fff;
    // border-radius: 0%;
    // box-shadow: none;

  }
}
.ant-tooltip {
  max-width: 100% !important;
}
.luggage-tooltip {
  p {
    color: #1da023;
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
    .ant-icon,
    i {
      font-size: 18px;
      margin-right: 5px;
    }
    &:nth-child(1) {
      color: #fff;
    }
    &:nth-child(2) {
      color: #fff;
    }
  }
}
.collapse-header {
  .flight-icon {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      height: 40px;
      width: 40px;
    }
    .flight-names-block {
      p {
        font-size: 12px;
        margin-bottom: 0;
        &:nth-child(1) {
          font-size: 20px;
          @include mobile {
            font-size: 14px;
          }
        }
      }
    }
  }
  .ant-row {
    align-items: center;
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .modal-flight-icon {
    i {
      margin-right: 10px;
      font-size: 18px;
      @include mobile {
        font-size: 15px;
      }
    }
    span {
      @include mobile {
        font-size: 13px;
      }
    }
  }
  .timer-flight-icon {
    span {
      margin-right: 10px;
      @include mobile {
        font-size: 13px;
      }
    }
    .icon {
      font-size: 18px;
      @include mobile {
        font-size: 15px;
      }
    }
  }
}
.combined-view {
  //   margin-bottom: 10px;
  height: 100%;
  .flight-trip-details {
    padding: 15px;
  }
}
/*********** Seperate View ******/
.seperate-view {
  margin-bottom: 10px;
  .right-flight-details {
    border-bottom: 1px solid #ccc;
    padding: 15px;
    &:last-child {
      border: none;
    }
  }
}
.break-journey-city {
  border: 1px solid #0775e2;
  position: relative;
  margin: 0 5px;
  flex: 1;
  .inner_wrapper {
    position: absolute;
    width: 100%;
    text-align: center;
    .stop_points {
      display: inline-block;
      margin: 5px;
    }
  }

  .code-line-break {
    height: 7px;
    width: 7px;
    background-color: #0775e2;
    border-radius: 50%;
    margin: auto;
    margin-top: 12px;
    &::before {
      content: "";
      background-color: #0775e2;
      width: 20%;
      // top: 22px;
      height: 2px;
      left: 50px;
      position: absolute;
      @include mobile {
        width: 10%;
        left: 45px;
      }
    }
    &::after {
      content: "";
      background-color: #0775e2;
      width: 20%;
      // top: 22px;
      height: 2px;
      right: 47px;
      position: absolute;
      @include mobile {
        width: 10%;
        right: 45px;
      }
    }
  }
  .break-city-name {
    font-size: 10px;
    text-transform: uppercase;
    display:grid;
    &::before {
      position: absolute;
      height: 7px;
      top: -7px;
      width: 7px;
      margin: 0 2px;
      background-color: #0775e2;
      border-radius: 50%;

      border: 4px solid white;
      box-sizing: content-box;
      content: "";
    }
    .segment-span{font-size: 9px;
    text-transform: initial;
    color: #3082ed;}
  }
}
.change-timer {
  background-color: #eaebee;
  padding: 24px 60px;
  display: flex;
  align-items: center;
  .change-text {
    color: #e0760b;
    font-weight: 600;
    margin-right: 30px;
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
    }
    .icon-text {
      font-size: 14px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  .time-for-interchange {
    font-size: 13px;
  }
}
.combined-flight-results-container {
  height: 100%;
  .combined-flight-card {
    height: auto;
    .ant-card-body {
      height: 100%;
      .ant-row {
        height: 100%;
      }
      .flight-trip-details {
        height: 100%;
      }
    }
  }
}
.share-modal {
  width: 290px !important;
  border-radius: 10px;
}
.share-link-icons {
  display: flex;
  .common-share-icons {
    margin: 15px 30px;
    text-align: center;
    .anticon {
      font-size: 25px;
      color: #0775e2;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 0;
    }
  }
}
.flight-infoModal {
  text-align: center;
  .ant-modal-body {
    padding: 18px !important;
    text-align: center;
  }
  .ant-btn {
    margin-top: 15px;
  }
}

/* Dec-10-2020 */
.itinary-flight-name p {
  margin-bottom: 0px !important;
}

.combined-flight-results-container .ant-card-bordered {
  background-color: rgba(0, 0, 0, 0.03) !important;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
  border: 0 solid #f0f0f0 !important;
}
.share-price-mail {
  display: flex;
}
/*---22/07/2020---*/
.token-number-flught {
  display: flex;
}
.flight-syt-time {
  position: absolute;
  top: 62px;
  left: 205px;
}
.details-bag-icon {
  display: flex;
  margin-bottom: 0px;
  line-height: 13px;
  align-items: center;
  margin-bottom: 0;
  color: #0775e2;
  font-size: 13px;
}

.details-bag-icon {
  position: absolute;
  top: 69px;
  right: 14px;
}
@media screen and (max-width: 768px) {
  .itinary-details {
    display: block !important;
  }
  .refundText {
    position: absolute;
    top: 72px;
    right: 146px;
    font-size: 11px !important;
  }
  .flight-syt-time {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }
  .details-bag-icon {
    top: 72px !important;
  }
  .flight-timings .facility-icons {
    width: auto !important;
  }
  .flight-timings {
    margin-top: 10px;
    margin-bottom: -20px;
  }
}
.promoData {
  text-align: center;
  padding: 5px 0;
  background: #3082ed;
  color: #fff;
}
.flight-multi_fare {
  background: #EEF1FA;

  text-align: left;
}
.flight-multi_fare_data
{
 
  text-align: left;
}
.flight-fare-data {
  padding: 15px;
  align-items: center;
  display: flex;

}
.flight-multi_fare_data:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.flight-bagges{
  position:static;
}
.ant-modal-title{
  color: white;
}