.ticket-info-lowerpart > * {
    font-family: monospace;
    font-weight: 800;
}

/* Apply styling for the table */
.table {
    width: 100%;
    border-collapse: collapse; /* Ensures borders are collapsed and uniform */
  }
  
  /* Apply styles to td elements */
  .table td {
    font-size: 13px; /* Font size for td elements */
    padding: 8px; /* Add padding for better spacing */
    border-bottom: 1px solid #dee2e6; /* Light gray border between rows */
  }
  
  /* Left-align the strong text inside the first td */
  .table td strong {
    text-align: left; /* Align <strong> content to the left */
    display: inline-block; /* Ensure it is treated as inline-block for alignment */
    width: 100%; /* Ensure the <strong> tag takes up full width of the td */
  }
  
  /* Right-align the second td content */
  .table td:nth-child(2) {
    text-align: right; /* Align the content of the second td to the right */
    border-left: 1px solid #dee2e6; /* Add a border between the two td elements */
  }
  
  /* Add optional styling to the table borders */
  .table td,
  .table th {
    border: 1px solid #ddd; /* Adds borders around each td and th */
  }
  