@import "../../../assets/variableStyles.scss";

.flight-deals-details {
  .deals-details-wrapper {
    background-image: url("../../../assets/images/deals_details_bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    margin-top: -67px;
    padding-top: 387px;
    position: relative;
    @include mobile {
      background-position: center;
    }
    .bread-crumb-block {
      // padding-top: 67px;
      position: absolute;
      top: 70px;
      @include mobile {
        top: 120px;
      }
      ul.breadcrumb {
        padding: 10px 16px;
        list-style: none;
        background-color: transparent;
      }
      ul.breadcrumb li {
        display: inline;
        font-size: 12px;
      }
      ul.breadcrumb li + li:before {
        padding: 8px;
        color: #dcdee3;
        content: "/\00a0";
      }
      ul.breadcrumb li a {
        color: #dcdee3;
        text-decoration: none;
      }
      ul.breadcrumb li a:hover {
        color: #dcdee3;
        text-decoration: underline;
      }
      ul.breadcrumb li span {
        color: #fff;
        font-weight: 700;
      }
    }
    .main-text {
      position: absolute;
      top: 50%;
      left: 25%;
      @include mobile {
        top: 56%;
        left: 6%;
      }
      h2 {
        color: #fff;
        font-family: Ubuntu, sans-serif;
        font-size: 45px;
        @include mobile {
          font-size: 33px;
          font-weight: 600;
          text-align: center;
        }
      }
    }
  }
  .deals-details-content-wrapper {
    padding: 30px 0px;
    background-color: #f6f7f8;
    .looking-flights {
      margin-bottom: 40px;
      h3 {
        text-align: center;
        margin-bottom: 25px;
        @include mobile {
          font-size: 19px;
          font-weight: 600;
        }
      }
      p {
        @include mobile {
          font-size: 13px;
        }
      }
    }
    .popular-flights {
      margin-bottom: 40px;
      .popular-text {
        text-align: center;
        h3 {
          @include mobile {
            font-size: 19px;
            font-weight: 600;
          }
        }
        p {
          padding: 0 6%;
          @include mobile {
            font-size: 13px;
          }
        }
      }
      .popular-cards {
        .ant-card {
          border-radius: 5px;
        }
        .ant-card-body {
          padding: 0px !important;
          border-bottom: solid 2px #002273;
          border-radius: 5px;
        }
        .flights-travel-list {
          li {
            border-bottom: solid 1px #ccc;
            padding: 0px 15px 0px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:hover {
              background-color: #f6f6f6;
              a {
                color: #002273 !important;
                text-decoration: underline;
                text-decoration-color: #002273;
              }
            }
            a {
              padding: 10px 10px;
              color: #333 !important;
              font-size: 20px;
              @include mobile {
                font-size: 13px;
                font-weight: 600;
              }
            }
            p {
              margin-bottom: 0px !important;
              color: #002273;
              font-size: 15px;
            }
          }
          .list-with-img {
            display: flex;
            justify-content: space-between;
            text-decoration: none !important;
            padding: 8px 15px 8px 0px;
            @include mobile {
              padding: 10px;
            }

            .imageItem {
              padding-left: 25px;
              width: 90px;
              height: 40px;
              img {
                height: 100%;
              }
            }
            .airline-btn {
              color: #fff;
              background-color: #ea1b37;
              padding: 10px 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 15px;
              font-weight: 400;
              width: 275px;
              @include mobile {
                width: 166px;
                padding: 10px 20px;
                font-size: 12px;
                margin-left: 38px;
              }
            }
          }
        }
        .left-card {
          margin-right: 10px;
          @include mobile {
            margin-right: 0px;
          }
        }
        .right-card {
          margin-left: 10px;
          @include mobile {
            margin-left: 0px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
