.slider-img-holidays {
  width: 100% !important;
  max-height: 460px;
}
.holidays-near {
  margin-top: 30px;
  font-family: "GT Eesti Pro Display", Arial, sans-serif !important;
}
.price-gua {
  background: transparent !important;
  color: #000000 !important;
  border-color: transparent !important;
  padding: 0px 0px !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.035) !important;
}
.price-gua:focus,
.price-gua:hover {
  border-color: transparent !important;
}
.ant-popover-inner-content {
  width: 251px;
}
.all-near-places-1 {
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 8px 20px !important;
  background-color: white !important;
  border: solid 1px #ea1b37 !important;
  color: #ea1b37 !important;
  border-radius: 2px !important;
  height: auto !important;
  width: 100%;
}

.all-near-places-1:hover {
  background-color: #ea1b37 !important;
  color: #ffffff !important;
}
.review-holi li {
  float: left;
  padding: 10px 10px;
}
.star-rating {
  font-size: 16px;
  color: #ffa628;
}
.border-top-line {
  border-top: 1px solid #eee;
}
.border-top-line-1 {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.cancel-no-policY {
  padding-top: 20px;
}
.cancel-no-policY li {
  float: left;
  padding: 10px 43px;
}
.largest-value li {
  padding: 10px;
}
.act_booking_title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  color: #333333;
}
.package-type {
  padding-top: 38px;
}
.select-date-option {
  background-color: #f0f6f9 !important;
}
.act_unit__name {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #333;
}
.act_unit {
  line-height: 15px;
  font-size: 12px;
  color: #ffa628;
}
.rating-number {
  display: flex;
}
.market_price {
  line-height: 21px;
  font-size: 12px;
  color: #999999;
  text-decoration: line-through;
  margin-top: 10px;
}
.selling_price {
  margin-left: 8px;
  line-height: 21px;
  font-size: 16px;
  font-weight: 600;
  color: #ff5722;
  margin-top: 10px;
}
.pkg-terms li {
  padding-bottom: 10px;
}
.side_package__keywords {
  padding: 20px;
  border-radius: 2px;
  background-color: #fff;
}
.keywords-insta li {
  font-size: 14px;
  line-height: 20px;
  color: #333;
  padding-bottom: 10px;
}
.side_package__main {
  position: relative;
  margin-top: 16px;
  padding-bottom: 50px;
  max-height: calc(
    100vh - 110px - 20px - 80px - 20px - 40px - 20px - 26px - 30px
  );
  max-height: 220px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}
.datail-pkg::before {
  content: "";
  height: 16px;
  width: 4px;
  background: #e64340;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.datail-pkg {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #333333;
  position: relative;
  padding-left: 14px;
}
.trip-uses li {
  margin-bottom: 8px !important;
}
.booking-option {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #424242;
  padding-left: 16px;
  padding-top: 10px;
}
.booking-option::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 20px;
  background-color: #ff5722;
  border-radius: 5px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.option-sec {
  margin-top: 20px;
}
.child-person {
  margin-top: 25px;
}
.div--price_total {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #ff5722;
}
.cart-btn {
  display: flex;
}
.addtocart {
  justify-content: center;
  padding: 0 20px;
  font-weight: 500;
  color: #fff;
  line-height: 22px;
  font-size: 18px;
  border-radius: 2px;
  background-color: #ffa628;
  height: 48px;
}
.book-cart {
  justify-content: center;
  padding: 0 20px;
  font-weight: 500;
  color: #fff;
  line-height: 22px;
  font-size: 18px;
  border-radius: 2px;
  background-color: #ff5722;
  height: 48px;
}
.img-boottom-para {
  margin-top: 15px;
}
.ramoji-film-city-maps {
  width: 100%;
  height: 350px;
}
.start-review-rating li {
  float: left;
  padding: 0px 7px;
  font-size: 40px;
  font-weight: bold;
  color: #424242;
}
.review-count {
  font-size: 18px !important;
  line-height: 73px;
  color: #666;
}
.start-review-rating li .fa {
  color: #ffa628;
  font-size: 23px;
}
.gallery-wrapper {
  margin-top: 30px;

  padding: 16px;

  background-color: #e4f3fd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.gallery-wrapper .gallery-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.slider-img-holidays-1 {
  width: 120px;
  height: 120px;
  border-radius: 4px;
  cursor: pointer;
}
.view-more-photos {
  text-align: right;
  margin-bottom: 20px;
}
.forms-rating {
  margin-top: 20px;
}
.five-star {
  color: #ffa628;
  padding: 0px 4px;
}
.rating-button {
  text-align: right;
}
.star-btn-one {
  color: #fff;
  background-color: #ff5722;
  height: 32px;
  font-size: 14px;
  line-height: 16px;
  padding: 0px 20px;
}
.form-padding {
  padding: 0px 10px;
}
.review-sort {
  display: flex !important;
}
.persons-rating {
  display: flex;
}
.persons-rating p {
  padding: 0px 5px;
}
.help-ful-btn {
  margin-top: 25px;
}
.review-help-center {
  background-color: #ffffff;
  padding: 15px;
}
.date-of-review {
  text-align: right;
}
.recent-review-module {
  padding: 40px 0 32px;
  border-bottom: 1px solid #e0e0e0;
}
.recent-review-module-1 {
  border-bottom: 1px solid #e0e0e0;
}
.center-line {
  margin-bottom: 20px;
}
.ft-top {
  margin-top: 25px;
}
.places-promo-btn {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.12);
}
.credits-p {
  padding-top: 20px;
}
.bread-cubs {
  margin-top: 35px;
}
.bread-cubs .ant-breadcrumb-separator {
  display: none;
}
.bread-cubs .ant-breadcrumb-link {
  padding: 20px 7px;
}
.bread-cubs .ant-breadcrumb-link a {
  color: #1a2b49;
  font-weight: 600;
}

.groupfrom .form-group {
  margin-bottom: 0rem !important;
}

/*css--09-2021*/
.cms-pages-width-places {
  width: 1170px;
}
.new-sec-1key {
  border: 1px solid;
  border-color: #e2e2e2 #e2e2e2 #1fa1d3 #e2e2e2;
  background-color: #fff !important;
  padding: 0px 20px;
  padding-bottom: 25px;
  padding-top: 25px;
}

.activitiestourform {
  .ant-select-selection-item {
    white-space: pre-wrap;
    color: #000;
  }

  .ant-form-item {
    margin-bottom: 4px;
  }
}

.activityInfoTooltip {
  width: fit-content !important;
  max-width: 250px !important;
}

.key-option {
  height: 40px;
  display: table;
}
.key-option .tour-favi {
  font-size: 15px;
  color: #00295d;
  background: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  box-shadow: 0 0 6px 1px #888;
  text-align: center;
  float: left;
}
.aling-middle {
  line-height: 40px;
  padding-left: 15px;
}
.key-row-2 {
  padding-top: 25px;
}
.des-row-key {
  margin-top: 25px;
}
// .deatils-key {
//   font-size: 10px;
// }
.inclu li:before {
  color: #98ce44;
  font-family: FontAwesome;
  margin-right: 8px;
  background: 0 0;
  content: "\f00c";
}
.inclu li {
  font-size: 14.5px;
}
.importinfo li {
  font-size: 14.5px;
  list-style: inside;
}
@media screen and (max-width: 768px) {
  .cms-pages-width-places {
    width: 100% !important;
  }
  .key-option {
    margin-bottom: 8px;
  }
  .img-inclusion-c2 img {
    width: 100% !important;
  }
}
.book-cart-all {
  justify-content: center;
  padding: 0 20px;
  font-weight: 500;
  color: #fff;
  line-height: 22px;
  font-size: 18px;
  border-radius: 2px;
  background-color: #ea1b37;
  height: 38px;
  line-height: 38px;
  float: right;
  border-radius: 5px;
}
.emailflyer {
  text-align: right;
}
.flyerbtn-1 {
  background-color: #ea1b37 !important;
  border-color: #fff;
  color: #fff !important;
  height: 40px;
  padding: 0 24px;
  line-height: 28px;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 700;
}

.flyerbtn-2 {
  background: #0098c9;
  height: 28px;
  padding: 0 24px;
  line-height: 28px;
  font-size: 14px;
  color: #ffffff;
}

/*review--page--cs--*/
.tab-content-1 ul {
  width: 100%;
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 0;
}
.tab-content-1 ul li {
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 0px 0;
  width: 100%;
  font-size: 15px;
  color: #676666;
  line-height: 32px;
  border-bottom: 1px dashed #dfe2e2;
}
.tab-content-1 ul li:last-child {
  border-bottom: 0;
}
.hm_link-1 {
  font-size: 15px;
  color: #676666;
  text-decoration: none;
  cursor: pointer;
}
.srch_btn-1 {
  background: #fff;
  padding: 0 10px;
  line-height: 31px;
  position: relative;
  outline: 0;
  display: inline-block;

  text-align: center;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.review-top {
  padding-top: 25px;
  border-top: 1px solid #ededed;
}

.option_Name {
  width: 400px;
}
.transfer_option {
  width: 150px;
}

.selectDate {
  width: 135px;
}
/*build-activitity*/
@media screen and (max-width: 768px) {
  .group-one-person-1 {
    width: 210px;
  }
  .group-one-person-2 {
    width: 165px;
  }
  .group-one-person-3 {
    width: 116px;
  }
  .group-one-person-4 {
    width: 50px;
  }
  .group-one-person-5 {
    width: 50px;
  }
  .group-one-person-6 {
    width: 50px;
  }
}

.slot-modal-nav .ant-modal-body {
  padding: 0px !important;
}
.time-slot-activity {
  border-bottom: 1px solid #dadada;
  display: inline-flex;
  width: 100%;
  height: 46px;
  background-color: #f7f7f7;
  padding: 15px;
}
.slot-change {
  color: #e01a33 !important;
  margin-left: 15px;
  font-size: 14px !important;
}
.time-slot-activity .fa {
  font-size: 23px;
}
.date-slot {
  font-size: 15px;
}
.tourModal-wrapper {
  padding: 8px 15px;
  color: #345088;
}

.select-slot-time h4 {
  font-size: 18px;
  line-height: 34px;
  padding: 8px 15px;
  color: #f2a818;
  margin-bottom: 0;
}
.select-slot-time-1a h4 {
  font-size: 16px;
  line-height: 10px;
  padding: 8px 0px;
  color: #f2a818;
}
.select-slot-slod-out {
  padding: 0 15px;
}
.select-slot-date-map {
  padding: 8px 15px;
  max-height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: 1px solid #ededed;
}
.one-slot-book {
  background: #f2eabc;
  color: #1a1a1c;
  min-width: 100px;
  text-align: center;
  padding: 0px 0px;
  word-break: break-word;
  border-radius: 0 0 5px 5px;
  width: 134px;

  .time-slot-available {
    text-align: center;
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
    background-color: #a4d77f;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }

  .time-slot-unavailable {
    text-align: center;
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
    background-color: #ff0000;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .time-slot-limited {
    text-align: center;
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
    background-color: #fed301;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }

  .priceWrapper {
    padding-top: 6px;
  }
}
.selected {
  border: 2px solid #90d153;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.av-3 {
  color: #fed301;
}
.av-1 {
  color: #a4d77f;
}
.av-2 {
  color: red;
}
.data-adult {
  margin-bottom: 0px;
  padding-top: 6px;
}
.select-slot-date-map .ant-col {
  margin-bottom: 13px;
}
.from-slot-label {
  padding: 8px 15px;
  justify-content: center;
  margin-top: 25px;
}
.from-slot-label .ant-form-item-label {
  font-weight: bold;
}
.total-slot-bbok {
  padding: 8px 15px;
}
.total-slot-bbok p {
  font-size: 22px;
}
.total-slot-bbok .slot-span {
  color: #ea1b37 !important;
  font-weight: 700;
}
.slot-check-out {
  text-align: end;
}
.slot-btn {
  background-color: #ea1b37 !important;
  border-color: #ea1b37 !important;
  font-size: 16px;
  outline: 0;
  border: 0;
  font-weight: 600;
  color: #ffffff;
}
.slot-modal-nav .ant-modal-footer {
  display: none;
}
.slot-collapse .ant-collapse-header {
  font-size: 15px;
  font-weight: 600;
}
.dates-option-slot {
  border-bottom: 1px solid #e6e6e6;
}

.dates-option-slot li {
  border-bottom: 1px solid #e6e6e6;
}

.dates-option-slot li a {
  display: table;
  font-size: 17px;
  line-height: 45px;
  width: 100%;
  color: #333;
  height: 45px;
}
.dates-option-slot li a:hover {
  background: #f4f7f2;
}
.select-time-fun:focus {
  box-shadow: none !important;
}
.select-time-fun {
  color: #ea1b37 !important;
  text-decoration: none;
  font-weight: 700;
}
.slot-modal-nav .ant-modal-title {
  color: #ffffff !important;
}
.slot-graph-modal {
  margin-bottom: 0px;
  padding-left: 18%;
}
