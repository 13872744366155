.blog-info-bg {
  background-image: url("../../assets/images/blog-sec-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  padding-top: 447px;
  position: relative;
  background-position: center;
}
.bg-info-heading {
  position: absolute;
  top: 32%;
  left: 0;
  right: 0;
  z-index: 1;
}
.blog-description {
  text-align: center;
  color: #ffffff;
  font-size: 32px;
}
.sec-width-blog {
  max-width: 46%;
  text-align: center;
  display: block;
  margin: 0 auto;
}
.trips-blog-sec li {
  display: inline;
  color: #ffffff;
  padding: 0px 3px;
}
.blog-info-social {
  display: block;
  text-align: center;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.blog-info-social li {
  display: inline;
  cursor: pointer;
  padding: 0px 10px;
}

.blog-info-social li .fa {
  color: #002071 !important;
  font-size: 23px !important;
}
.text-blog-info {
  text-align: left;
  font-size: 19px;
  color: #002071;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 33px;
}
.para-info-sec {
  color: #78859e;
  font-weight: 400;
  text-align: left;
  font-size: 16px;
}
.second-col-sec {
  margin-top: 25px;
  margin-bottom: 25px;
}
.blog-info-img-sec {
  width: 900px;
  height: 600px;
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}
.blog-info-img-sec-1 {
  width: 900px;
  height: 600px;
  display: block;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 30px;
}
.offers-sec {
  text-align: center;
}
.bd-top-btm {
  border-style: dashed;

  border-top-width: 1px !important;
  border-right-width: 0px !important;
  border-bottom-width: 1px !important;
  border-left-width: 0px !important;
  padding-top: 20px !important;
  padding-right: 0px !important;
  padding-bottom: 20px !important;
  padding-left: 0px !important;
  margin-bottom: 20px;
}
.btn-f-h {
  text-align: center;
}
.btn-flights-wdh {
  border-color: #ea1b37 !important;
  width: 25%;
  font-weight: 600 !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 15px !important;
  height: 48px;
}
.btn-flights-wdh:hover {
  background-color: #ea1b37 !important;
  border-color: #ea1b37 !important;
  color: #ffffff !important;
}

.btn-hotel-wdh {
  border-color: #0775e2 !important;
  width: 25%;
  font-weight: 600 !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 15px !important;
  height: 48px;
}
.btn-hotel-wdh:hover {
  border-color: #0775e2 !important;
  background-color: #0775e2;
  color: #ffffff !important;
}

.comment_wrapper .comments_area {
  max-width: 800px;
  margin: 0 auto 0 15px;
}

@media screen and (max-width: 768px) {
  .sec-width-blog {
    max-width: 100%;
    text-align: center;
    display: block;
    margin: 0 auto;
  }

  .blog-info-img-sec {
    width: 100%;
  }
  .blog-info-img-sec-1 {
    width: 100%;
  }
  .btn-flights-wdh {
    width: 100%;
  }
  .btn-hotel-wdh {
    width: 100%;
  }
}
