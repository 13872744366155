.payment-btn {
  height: auto;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  background-color: #59dd50 !important;
  color: #fff;
  border: none;
  border-radius: 8px;
}
.payment-btn:hover {
  height: auto;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  background-color: #ea1b37 !important;
  color: #fff;
  border: none;
  border-radius: 8px;
}
