@import "../../assets/variableStyles.scss";
#home-page {
  //background-color: #f4f5f6;
  .hero-banner {
    position: relative;
    z-index: 1;
  }
  .banner-links {
    margin-bottom: 30px;
    background-color: #fff;
    padding: 15px 20px;
    box-shadow: 0 2px 4px 0 #dcdee3;
    .banner-links-wrapper {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        li {
          list-style-type: none;
          display: flex;
          padding: 0px 13px;
          p.banner-icon {
            font-size: 25px;
            margin-bottom: 0px;
            padding-right: 15px;
            display: table-cell;
          }
          .banner-box {
            label {
              display: block;
              text-transform: uppercase;
              margin-bottom: 0px;
              font-size: 11px;
              color: #272d38;
              font-weight: 700;
            }
            a {
              color: #0775e2;
              font-size: 13px;
              letter-spacing: 0.5px;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  .topflights_wrapper {
    max-width: 1140px;
    margin: 0 auto;
    padding: 15px;

    .domesticFlights,
    .intFlights {
      h2 {
        text-align: center;
        margin-bottom: 20px;
        @include mobile {
          font-size: 24px;
        }
      }
    }
    .intFlights {
      margin-top: 40px;
    }

    .popular-cards {
      .ant-card {
        border-radius: 5px;
      }
      .ant-card-body {
        padding: 0px !important;
        border-bottom: solid 2px #002273;
        border-radius: 5px;
      }
      .flights-travel-list {
        li {
          border-bottom: solid 1px #ccc;
          padding: 0px 15px 0px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            background-color: #f6f6f6;
            a {
              color: #002273 !important;
              text-decoration: underline;
              text-decoration-color: #002273;
            }
          }
          a {
            padding: 10px 10px;
            color: #333 !important;
            font-size: 20px;
            @include mobile {
              font-size: 13px;
              font-weight: 600;
            }
          }
          p {
            margin-bottom: 0px !important;
            color: #002273;
            font-size: 15px;
          }
        }
        .list-with-img {
          display: flex;
          justify-content: space-between;
          text-decoration: none !important;
          padding: 8px 15px 8px 0px;
          @include mobile {
            padding: 10px;
          }

          .imageItem {
            padding-left: 25px;
            width: 90px;
            height: 40px;
            img {
              height: 100%;
            }
          }
          .airline-btn {
            color: #fff;
            background-color: #ea1b37;
            padding: 10px 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-weight: 400;
            width: 275px;
            @include mobile {
              width: 166px;
              padding: 10px 20px;
              font-size: 12px;
              margin-left: 38px;
            }
          }
        }
      }
      .left-card {
        margin-right: 10px;
        @include mobile {
          margin-right: 0px;
        }
      }
      .right-card {
        margin-left: 10px;
        @include mobile {
          margin-left: 0px;
          margin-top: 15px;
        }
      }
    }
  }
}

/*  ===============  Styles for Need hotel Room ============ */
.need-hotel-room-section {
  background-color: #eaf2fc;
  background-image: url("../../assets/images/footer/stars_desktop.png");
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile {
    background-image: url("../../assets/images/footer/stars_mobile.png");
    background-size: contain;
    background-position: center;
  }
  .hotel-room {
    max-width: 700px;
    margin: 0 auto;
    padding: 38px 15px 38px 50px;
    min-height: 180px;
    @include mobile {
      padding: 38px 15px;
    }
    .hotel-icon {
      float: left;
      position: relative;
      background-image: url("../../assets/images/footer/sprite_footer.png");
      background-position: 0 0;
      width: 105px;
      height: 99px;
      @include mobile {
        display: none;
      }
    }
    .hotel-room-text {
      margin-left: 190px;
      text-align: left;
      @include mobile {
        margin-left: 0px;
        text-align: center;
      }
      .room-title {
        font-size: 27px;
        line-height: 35px;
        font-weight: 400;
        margin: 0 auto 12px;
        color: #002172;
        a {
          color: #fff;
          background-color: #0775e2;
          border-radius: 3px;
          min-height: 50px;
          padding: 10px 30px;
          font-size: 16px;
          text-align: center;
          display: inline-block;
          text-decoration: none;
          font-weight: 700;
        }
      }
    }
  }
}

.icon-info {
  margin-right: 9px;
  width: 41px;
  background: #002273;
  height: 41px;
  line-height: 41px;
  color: #ffffff;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
}
.top-fl-route {
  color: #000;
  font-size: 25px;
  text-align: center;
  margin: 0 0 15px;
  font-weight: 600;
  position: relative;
  margin-top: 35px;
}
.top-fl-route::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 10px auto;
  width: 10%;
  height: 3px;
  background: #505050;
}
.tab-content ul {
  width: 100%;
  float: left;
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 0;
}
.tab-content ul li {
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 4px 0;
  width: 100%;
  font-size: 15px;
  color: #676666;
  line-height: 32px;
  border-bottom: 1px dashed #dfe2e2;
}
.tab-content ul li:last-child {
  border-bottom: 0;
}
.hm_link {
  font-size: 15px;
  color: #676666;
  text-decoration: none;
  cursor: pointer;
}
.srch_btn {
  background: #fff;
  float: right;
  color: #ea1b37;
  border-radius: 4px;
  border: 1px solid #ea1b37;
  padding: 0 10px;
  line-height: 31px;
  position: relative;
  outline: 0;
  display: inline-block;
  min-height: 36px;
  /* min-width: 88px; */
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.top-route-bb {
  background-color: #ffffff;
  margin-bottom: 0px;
}
.sec-bg-top {
  width: 100%;
  background: #fff;
  border: 1px solid #dfe2e2;
  border-bottom: 2px solid #dfe2e2;
  margin-top: 35px;
  border-radius: 3px;
  display: inline-block;
  padding: 1% 2%;
}
.gap-cols-sec {
  padding-left: 8px;
  padding-right: 8px;
}
.offers-bal-cart {
  display: flex;
  background: #fff;
  border: 1px solid #e5f1ee;
  border-radius: 4px;

  color: #000;
  padding: 7px 6px;
  position: relative;
  z-index: -1;
}
.deals-coloums-24 {
  height: 34px;
}
.price-deals-24 p {
  margin: 7px !important;
  font-size: 14px;
}
.price-deals-24 {
  padding-left: 10px;
}
.support-24 {
  padding-left: 13px;
}
@media screen and (max-width: 768px) {
  .support-24 {
    padding-left: 0px;
  }
  .step-img-value {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .step-img-tai {
    width: 100%;
  }
  .book-step-option {
    margin-bottom: 10px;
  }
}
.home-best-24 {
  padding-top: 25px;
}
.home-best-trip p {
  margin-bottom: 8px;
}
.home-best-trip {
  margin-bottom: 0px;
}
/*18-09-2021*/
.top-fl-route-email {
  color: #fff;
  font-size: 25px;
  text-align: center;
  margin: 0 0 15px;
  font-weight: 600;
  position: relative;
  margin-top: 16px;
}
.top-fl-route-email::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 10px auto;
  width: 10%;
  height: 3px;
  background: #fff;
}
.stay-best-email {
  background-color: #2f81ed;
  background-image: linear-gradient(to right, #2f80ed, #56ccf2);
  margin-bottom: 30px;
  padding-bottom: 20px;
  margin-top: 30px;
}
.news-sub-tt {
  height: 60px;
}
.sub-text-up {
  display: flex;
}
.date-news-travel {
  padding-top: 3px;
  padding-bottom: 10px;
  padding-left: 10px;
}
.sub-text-up p {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 700;
}
.home-from-sub {
  padding-top: 25px;
}
.name-sub-date {
  height: 52px;
  font-size: 14px;
  border-radius: 6px;
  width: 98%;
}
.cribe-btn-email {
  background: #ea1b37 !important;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 16px;
  outline: 0;
  border: 0;
  font-weight: 600;
  height: 52px;
  border-radius: 6px !important;
  width: 100%;
}
.new-img-sec-c1 {
  border-top-left-radius: 40px;
}
.step-img-value {
  width: 630px;
  height: 350px;
  border-top-left-radius: 40px;
}
.step-img-tai {
  width: 96%;
  height: 350px;
}
.img-ft-img-sec {
  margin-top: 40px;
  margin-bottom: 20px;
}
.rate-btn-from {
  width: 96%;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
}
.offers__info_rates {
  padding-bottom: 6px;
  padding-left: 5px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}
.offers__info_rates P {
  margin-bottom: 0px;
  color: #fff;
}
.st-flex {
  display: flex;
}
.sg-country-name {
  font-size: 18px;
  color: #ea1b37 !important;
  font-weight: 600;
  padding-left: 10px;
}
.book-sg-country {
  background: #ea1b37 !important;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  outline: 0;
  border: 0;
  width: 100%;
}
.hm_link:hover {
  color: #ea1b37 !important;
}
.route-trip-3 {
  margin-bottom: 36px;
}
.popular-cities-link {
  color: #000;
  display: block;
}
.popular-cities-link:hover {
  color: #000;
}
